import { useState } from 'react';

import { useTranslate } from 'services/i18n/useTranslate';
import { useDownloadAuditLogs } from 'services/api/auditLogs';
import { useLogout } from 'services/api/auth';

import { Authorize } from 'components/common/Authorize';
import { AdminUserIcon } from 'components/common/Icons';
import { IconButton } from 'components/common/IconButton';
import { DropdownMenu } from 'components/common/DropdownMenu';

import { UserSettingsSidebar } from './UserSettingsSidebar';

export const UserMenu = () => {
  const t = useTranslate();
  const logoutMutation = useLogout();

  const downloadAuditLogsMutation = useDownloadAuditLogs();
  const [isUserSettingsOpen, setIsUserSettingsOpen] = useState(false);

  const handleLogout = () => {
    logoutMutation.mutate();
  };

  return (
    <>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger>
          <div className="pr-2">
            <IconButton
              size="4"
              variant="ghost"
              color="gray"
              highContrast
              className="mr-4 block text-base text-neutral-11"
              data-test-id="user-menu-button"
            >
              <AdminUserIcon className="size-5" />
            </IconButton>
          </div>
        </DropdownMenu.Trigger>

        <DropdownMenu.Content size="2">
          <DropdownMenu.Item onClick={() => setIsUserSettingsOpen(true)}>
            {t('user_menu.items.user_settings')}
          </DropdownMenu.Item>

          <Authorize
            policy={{
              scope: 'auditlog:read',
            }}
          >
            <DropdownMenu.Separator />
            <DropdownMenu.Item disabled>{t('user_menu.items.administrator')}</DropdownMenu.Item>
            <DropdownMenu.Item onClick={() => downloadAuditLogsMutation.mutate()}>
              {t('user_menu.items.download_audit_logs')}
            </DropdownMenu.Item>
          </Authorize>

          <DropdownMenu.Separator />
          <DropdownMenu.Item onClick={handleLogout}>{t('user_menu.items.sign_out')}</DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Root>

      <UserSettingsSidebar isOpen={isUserSettingsOpen} onOpenChange={setIsUserSettingsOpen} />
    </>
  );
};
