import { useMemo } from 'react';
import { format } from 'date-fns';
import type { HeaderGroup } from '@tanstack/react-table';
import { useNavigate } from '@tanstack/react-router';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  createColumnHelper,
} from '@tanstack/react-table';

import { LOADING_THREATS } from 'mocks/threat';
import { extractEntitiesFromThreat, getRiskScoreRange } from 'utils/detections';
import { dateTimeFormatWithoutSeconds } from 'consts/dateFormats';
import { useTenants } from 'services/api/tenants';
import { usePipelines } from 'services/api/pipelines';
import { useTranslate } from 'services/i18n/useTranslate';
import type { APIError } from 'services/api';
import type { components, paths } from 'types/schemas/api-schema';
import { useGetCallerDisplayName } from 'services/api/callers';

import { Text } from 'components/common/Text';
import { Button } from 'components/common/Button';
import { Badge } from 'components/common/Badge';
import { Skeleton } from 'components/common/Skeleton';
import { TextAlignLeftIcon } from 'components/common/Icons';
import { SortableColumnHeaderCell, Table } from 'components/common/Table';
import { ErrorBadge, NoDataBadge } from 'components/common/StatusBadges';

import {
  ThreatAssigneeBadge,
  ThreatEntityBadge,
  ThreatPipelineBadge,
  ThreatRiskScoreBadge,
  ThreatStatusBadge,
  ThreatTenantBadge,
  ThreatWorkflowStatusBadge,
} from '../common/ThreatsBadges';
import { useThreatsContext } from '../ThreatsContext';

type Threat = components['schemas']['threat'];

type ThreatsTableProps = {
  isLoading?: boolean;
  error?: APIError<
    paths['/detections/threats/{threatId}']['get']['responses']['404']['content']['application/json']
  > | null;
  threats: Threat[];
};

const DEFAULT_DATA: Threat[] = [];

const threatColumnHelper = createColumnHelper<Threat>();

const MAX_ENTITIES = 4;

export const ThreatsTable = ({ error, isLoading, threats }: ThreatsTableProps) => {
  const t = useTranslate();
  const navigate = useNavigate({ from: '/threats' });
  const tenantsQueryData = useTenants();
  const pipelinesQueryData = usePipelines();
  const { getIsFilterActive, toggleFilter } = useThreatsContext();
  const { getCallerDisplayName } = useGetCallerDisplayName();

  const isError = !!error;

  const columns = useMemo(() => {
    return [
      threatColumnHelper.accessor('riskScore.score', {
        enableSorting: false,
        meta: {
          rowSpan: 2,
        },
        header: () => <Text className="truncate">{t('common.risk_score')}</Text>,
        cell: (info) => (
          <Skeleton isLoading={isLoading}>
            <ThreatRiskScoreBadge
              riskScore={info.getValue()}
              isActive={getIsFilterActive('riskScore', info.getValue())}
              onClick={() => toggleFilter('riskScore', getRiskScoreRange(info.getValue()))}
            />
          </Skeleton>
        ),
      }),
      threatColumnHelper.accessor('createdAt', {
        enableSorting: false,
        meta: {
          rowSpan: 2,
        },
        minSize: 130,
        header: () => <Text className="truncate">Time</Text>,
        cell: (info) => {
          const threat = info.row.original;
          const firstEvidenceCreatedAt = threat.evidence[0]!.createdAt;
          const lastEvidenceCreatedAt = threat.evidence[threat.evidence.length - 1]?.createdAt;

          const shouldDisplayLastEvidence = lastEvidenceCreatedAt && firstEvidenceCreatedAt !== lastEvidenceCreatedAt;

          return (
            <Skeleton isLoading={isLoading}>
              <div className="text-xs leading-5 text-neutral-11">
                {shouldDisplayLastEvidence ? (
                  <>
                    {format(new Date(firstEvidenceCreatedAt), dateTimeFormatWithoutSeconds)} - <br />
                    {format(new Date(lastEvidenceCreatedAt), dateTimeFormatWithoutSeconds)}
                  </>
                ) : (
                  format(new Date(firstEvidenceCreatedAt), dateTimeFormatWithoutSeconds)
                )}
              </div>
            </Skeleton>
          );
        },
      }),
      threatColumnHelper.accessor('name', {
        enableSorting: false,
        meta: {
          rowSpan: 2,
        },
        header: () => <Text className="truncate">{t('common.name')}</Text>,
        cell: (info) => (
          <Skeleton isLoading={isLoading}>
            <Text className="truncate">{info.getValue()}</Text>
          </Skeleton>
        ),
      }),
      threatColumnHelper.accessor((x) => x, {
        id: 'entities',
        enableSorting: false,
        header: () => <Text className="truncate">{t('common.entities')}</Text>,
        cell: (info) => {
          const entities = extractEntitiesFromThreat(info.row.original);
          return (
            <div className="flex gap-2">
              {entities.slice(0, Math.min(entities.length, MAX_ENTITIES)).map((entity) => (
                <Skeleton isLoading={isLoading} key={entity.value}>
                  <ThreatEntityBadge
                    entity={entity}
                    isActive={getIsFilterActive('entity', entity.value)}
                    onClick={() => toggleFilter('entity', entity.value)}
                  />
                </Skeleton>
              ))}
              {entities.length > MAX_ENTITIES && (
                <Badge size="1" variant="soft" color="sky">
                  +{entities.length - MAX_ENTITIES}
                </Badge>
              )}
            </div>
          );
        },
      }),

      threatColumnHelper.accessor('status', {
        enableSorting: false,
        header: () => <Text className="truncate">{t('common.threat_status')}</Text>,
        cell: (info) => (
          <Skeleton isLoading={isLoading}>
            <ThreatStatusBadge
              status={info.getValue() as 'active' | 'inactive'}
              isActive={getIsFilterActive('status', info.getValue())}
              onClick={() => toggleFilter('status', info.getValue())}
            />
          </Skeleton>
        ),
      }),
      threatColumnHelper.accessor('workflow.status', {
        enableSorting: false,
        header: () => <Text className="truncate">{t('common.workflow_status')}</Text>,
        cell: (info) => (
          <Skeleton isLoading={isLoading}>
            <ThreatWorkflowStatusBadge workflowStatus={(info.getValue() as 'open' | 'closed') || 'open'} />
          </Skeleton>
        ),
      }),
      threatColumnHelper.accessor((x) => x.workflow.assignedTo, {
        id: 'assignee',
        enableSorting: false,
        header: () => <Text className="truncate">{t('common.assignment')}</Text>,
        cell: (info) => (
          <Skeleton isLoading={isLoading}>
            <ThreatAssigneeBadge>{getCallerDisplayName(info.getValue())}</ThreatAssigneeBadge>
          </Skeleton>
        ),
      }),
      threatColumnHelper.accessor('pipelineIds', {
        enableSorting: false,
        header: () => <Text className="truncate">{t('common.pipeline')}</Text>,
        cell: (info) => {
          const [pipelineId] = info.getValue();

          if (!pipelineId) {
            return null;
          }

          const pipeline = pipelinesQueryData.data?.data.find((p) => p.id === pipelineId);

          return (
            <Skeleton isLoading={isLoading}>
              <ThreatPipelineBadge
                isActive={getIsFilterActive('pipelineId', pipelineId)}
                onClick={() => toggleFilter('pipelineId', pipelineId)}
              >
                {pipeline?.name || pipelineId}
              </ThreatPipelineBadge>
            </Skeleton>
          );
        },
      }),
      threatColumnHelper.accessor('tenantId', {
        enableSorting: false,
        header: () => <Text className="w-full">{t('common.tenant')}</Text>,
        cell: (info) => {
          const tenant = tenantsQueryData.data?.data.find((t) => t.id === info.getValue());
          return (
            <Skeleton isLoading={isLoading}>
              <ThreatTenantBadge
                isActive={getIsFilterActive('tenantId', info.getValue())}
                onClick={() => toggleFilter('tenantId', info.getValue())}
              >
                {tenant?.name || info.getValue()}
              </ThreatTenantBadge>
            </Skeleton>
          );
        },
      }),
      threatColumnHelper.accessor('id', {
        enableSorting: false,
        header: () => <Text className="w-full text-right">{t('common.details')}</Text>,
        cell: (info) => {
          return (
            <Skeleton isLoading={isLoading}>
              <Button
                size="1"
                variant="soft"
                onClick={() => void navigate({ to: '/threats/$threatId', params: { threatId: info.getValue() } })}
              >
                <TextAlignLeftIcon /> {t('common.view')}
              </Button>
            </Skeleton>
          );
        },
      }),
    ];
  }, [
    getIsFilterActive,
    isLoading,
    navigate,
    pipelinesQueryData.data?.data,
    t,
    tenantsQueryData.data?.data,
    toggleFilter,
    getCallerDisplayName,
  ]);

  const table = useReactTable({
    data: isLoading ? LOADING_THREATS : (threats ?? DEFAULT_DATA),
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
  });

  return (
    <Table.Root className="mb-12 bg-neutral-2" size="1" data-test-id="anomalies-table">
      <Table.Header>
        {table.getHeaderGroups().map((headerGroup: HeaderGroup<Threat>) => (
          <Table.Row key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <SortableColumnHeaderCell
                key={header.id}
                header={header}
                style={{ minWidth: header.column.columnDef.minSize }}
                className="first-of-type:pl-2 last-of-type:pr-2"
              />
            ))}
          </Table.Row>
        ))}
      </Table.Header>

      <Table.Body className="[--table-cell-min-height:20px]">
        {isError ? (
          <Table.Row>
            <Table.Cell colSpan={columns.length}>
              <ErrorBadge error={error} className="w-full text-center" />
            </Table.Cell>
          </Table.Row>
        ) : table.getRowModel().rows.length === 0 ? (
          <Table.Row>
            <Table.Cell colSpan={columns.length} className="!px-2">
              <NoDataBadge className="w-full" />
            </Table.Cell>
          </Table.Row>
        ) : (
          table.getRowModel().rows.map((row) => {
            return (
              <Table.Row key={row.id} align="center" className="pt-2">
                {row.getVisibleCells().map((cell) => {
                  return (
                    <Table.Cell key={cell.id} className="!px-3 !pb-3 !pt-2 first-of-type:!pl-4 last-of-type:!pr-2">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            );
          })
        )}
      </Table.Body>
    </Table.Root>
  );
};
