import { lazy, useEffect, Suspense } from 'react';
import { Outlet, useMatches } from '@tanstack/react-router';

import { useTranslate } from 'services/i18n/useTranslate';
import type { FlattenedTranslationKeys } from 'types/translations';

import { Toaster } from 'components/common/Toast/Toaster';

const TanStackRouterDevtools =
  import.meta.env.DEV && false
    ? lazy(() =>
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
        })),
      )
    : () => null; // Render nothing in production

const TanStackReactQueryDevtools =
  import.meta.env.DEV && false
    ? lazy(() =>
        import('@tanstack/react-query-devtools').then((res) => ({
          default: res.ReactQueryDevtools,
        })),
      )
    : () => null; // Render nothing in production

export const Root = () => {
  const matches = useMatches();
  const t = useTranslate();

  useEffect(() => {
    const titles = matches
      .map((match) => {
        if (!match.meta) return undefined;

        return match.meta.find((item) => item?.name === 'metaTitle')?.content as FlattenedTranslationKeys;
      })
      .filter((title): title is FlattenedTranslationKeys => Boolean(title));

    const uniqueTitles = [...new Set(titles)];

    document.title = uniqueTitles.map((title) => t(title)).join(' / ') + ' - MixMode.ai';
  }, [t, matches]);

  return (
    <>
      <Outlet />
      <Toaster />
      <Suspense>
        <TanStackRouterDevtools position="top-left" />
        <TanStackReactQueryDevtools position="right" buttonPosition="bottom-left" />
      </Suspense>
    </>
  );
};
