import { useCallback, useMemo } from 'react';
import { format, addMilliseconds } from 'date-fns';
import { useNavigate } from '@tanstack/react-router';

import { timeFormatSimple } from 'consts/dateFormats';
import type { components } from 'types/schemas/api-schema';
import { useTranslate } from 'services/i18n/useTranslate';
import { useAnomalyHistogram, useAnomalyParams } from 'services/api/anomalies';

import {
  HistogramChart,
  type HistogramDataPoint,
  type HistogramTooltipProps,
  TooltipContainer,
} from 'components/common/HistogramChart';
import { HistogramPlaceholder } from 'components/common/HistogramChart/HistogramPlaceholder';

type HistogramData = {
  count: number;
};

const TooltipContent = ({
  active,
  payload,
  interval,
}: HistogramTooltipProps & {
  interval: number;
}) => {
  const t = useTranslate();
  const data = payload?.[0]?.payload as HistogramDataPoint<HistogramData> | undefined;

  if (!active || !data) {
    return null;
  }

  return (
    <TooltipContainer>
      <div className="text-neutral-1 dark:text-neutral-12">
        {t('anomalies.histogram.tooltip_interval', {
          time1: format(new Date(data.time), timeFormatSimple),
          time2: format(addMilliseconds(new Date(data.time), interval), timeFormatSimple),
        })}
      </div>

      <div className="text-neutral-10">
        {t('anomalies.histogram.tooltip_count', {
          count: data.data.count,
        })}
      </div>
    </TooltipContainer>
  );
};

const BUCKETS_TO_MILLISECONDS: Record<NonNullable<components['parameters']['detectionsBucketInterval']>, number> = {
  '5m': 5 * 60 * 1000,
  '20m': 20 * 60 * 1000,
  '1h': 60 * 60 * 1000,
  '2h': 2 * 60 * 60 * 1000,
  '6h': 6 * 60 * 60 * 1000,
  '12h': 12 * 60 * 60 * 1000,
  '1d': 24 * 60 * 60 * 1000,
};

const getBucketInterval = (from: Date, to: Date): components['parameters']['detectionsBucketInterval'] => {
  const TARGET_BUCKETS = 40;
  const diff = to.getTime() - from.getTime();
  const interval = diff / TARGET_BUCKETS;

  return Object.entries(BUCKETS_TO_MILLISECONDS)
    .sort((a, b) => a[1] - b[1])
    .find(([, value]) => value >= interval)?.[0] as components['parameters']['detectionsBucketInterval'];
};

const BAR_DEFINITIONS = {
  count: {
    color: 'var(--accent-a8)',
    activeColor: 'var(--accent-a10)',
  },
};

export const Histogram = () => {
  const t = useTranslate();
  const navigate = useNavigate({ from: '/detections' });
  const anomalyParams = useAnomalyParams();
  const anomalyHistogramQuery = useAnomalyHistogram({
    ...anomalyParams,
    bucketInterval: getBucketInterval(new Date(anomalyParams.startTime), new Date(anomalyParams.endTime)),
  });

  const intervalMilliseconds =
    BUCKETS_TO_MILLISECONDS[
      (anomalyHistogramQuery.data?.data?.bucketInterval as components['parameters']['detectionsBucketInterval']) || '5m'
    ];

  const chartData = useMemo(() => {
    return (
      anomalyHistogramQuery.data?.data.histogram.map((point) => ({
        time: point.time,
        data: {
          count: point.value,
        },
      })) || []
    );
  }, [anomalyHistogramQuery.data?.data.histogram]);

  const handleHistogramBrushChange = useCallback(
    (startTime: string, endTime: string) => {
      void navigate({
        search: {
          startTime,
          endTime,
        },
      });
    },
    [navigate],
  );

  if (anomalyHistogramQuery.isError) {
    return <HistogramPlaceholder message={t('common.errors.error_loading_histrogram')} />;
  }

  const hasNoData = anomalyHistogramQuery.data?.data?.histogram.every((point) => point.value === 0);
  if (hasNoData) {
    return <HistogramPlaceholder message={t('common.errors.no_data')} />;
  }

  return (
    <HistogramChart<HistogramData>
      isLoading={anomalyHistogramQuery.isLoading}
      data={chartData}
      interval={intervalMilliseconds}
      barDefinitions={BAR_DEFINITIONS}
      TooltipContent={(props) => <TooltipContent {...props} interval={intervalMilliseconds} />}
      onChange={handleHistogramBrushChange}
    />
  );
};
