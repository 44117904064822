export const PageBackground = () => {
  return (
    <div className="fixed inset-0 overflow-hidden opacity-60">
      {/* Noise texture */}
      <div
        className="-inset-1/6 absolute bg-repeat opacity-25 mix-blend-color-burn"
        style={{
          backgroundImage: `url('/src/static/images/noise.png')`,
        }}
      />

      {/* Brown ellipse blur */}
      <div className="absolute left-1/3 top-[5%] h-4/5 w-2/3 rounded-full bg-[#643102] opacity-30 blur-[509px]" />

      {/* Blue ellipse blur */}
      <div className="absolute h-screen w-2/3 rounded-full bg-[#5B68DF] opacity-30 blur-[509px]" />
    </div>
  );
};
