import { useRef, useState, type ReactNode } from 'react';

import { LOADING_THREATS } from 'mocks/threat';
import type { components } from 'types/schemas/api-schema';
import { useTranslate } from 'services/i18n/useTranslate';
import { useStore, type AllSlices } from 'services/store';
import { useThreats, useThreatsParams } from 'services/api/threats';

import { Text } from 'components/common/Text';
import { Select } from 'components/common/Select';
import { Button } from 'components/common/Button';
import { ScrollArea } from 'components/common/ScrollArea';
import { GridIcon, ListBulletIcon } from 'components/common/Icons';
import { PaginationControlPanel } from 'components/common/PaginationControlPanel';
import { ErrorBadge, NoDataBadge } from 'components/common/StatusBadges';

import { ThreatCard } from './ThreatCard';
import { ThreatsTable } from './ThreatsTable';
import { useThreatsContext } from '../ThreatsContext';

const ViewChangeButton = ({
  children,
  isActive,
  onClick,
}: {
  children: ReactNode;
  isActive: boolean;
  onClick: () => void;
}) => {
  return (
    <Button size="1" variant={isActive ? 'surface' : 'soft'} color={isActive ? 'orange' : 'gray'} onClick={onClick}>
      {children}
    </Button>
  );
};

const selector = (state: AllSlices) => ({
  viewMode: state.threats.viewMode,
  setViewMode: state.threats.setViewMode,
});

type SortByOptions = 'riskScoreDesc' | 'timeDesc' | 'timeAsc' | 'lastEvidenceDesc' | 'lastEvidenceAsc';

const getSortParam = (
  sortBy: SortByOptions,
): {
  sort: components['parameters']['simpleSort'];
  sortBy: components['parameters']['threatsSort'];
} => {
  switch (sortBy) {
    case 'riskScoreDesc':
      return {
        sort: 'desc',
        sortBy: 'riskScore',
      };
    case 'timeDesc':
      return {
        sort: 'desc',
        sortBy: 'createdAt',
      };
    case 'timeAsc':
      return {
        sort: 'asc',
        sortBy: 'createdAt',
      };
    case 'lastEvidenceDesc':
      return {
        sort: 'desc',
        sortBy: 'evidenceWindowEnd',
      };
    case 'lastEvidenceAsc':
      return {
        sort: 'asc',
        sortBy: 'evidenceWindowEnd',
      };
  }
};

const NO_THREATS_ARRAY: components['schemas']['threat'][] = [];
type ThreatsListProps = {
  onRefreshData: () => void;
};

export const ThreatsList = ({ onRefreshData }: ThreatsListProps) => {
  const t = useTranslate();
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const { viewMode, setViewMode } = useStore(selector);
  const { pagination, setPagination } = useThreatsContext();
  const [cardsSortBy, setCardsSortBy] = useState<SortByOptions>('riskScoreDesc');

  const handlePageSizeChange = (value: string) => {
    setPagination({
      pageIndex: 0,
      pageSize: Number(value),
    });
  };

  const previousPage = () => {
    setPagination({
      ...pagination,
      pageIndex: pagination.pageIndex - 1,
    });
  };

  const nextPage = () => {
    setPagination({
      ...pagination,
      pageIndex: pagination.pageIndex + 1,
    });
  };

  const threatParams = useThreatsParams();
  const threatsQuery = useThreats({
    ...threatParams,
    start: pagination.pageIndex * pagination.pageSize,
    limit: pagination.pageSize,
    ...getSortParam(cardsSortBy),
  });

  const canPreviousPage = pagination.pageIndex > 0;
  const totalCount = threatsQuery.data?.metadata.totalCount;

  const handleChangeViewMode = (mode: 'list' | 'cards') => {
    setViewMode(mode);

    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTo({
        top: 0,
        left: 0,
      });
    }
  };

  return (
    <div className="flex flex-col">
      <div
        className="flex items-center justify-between gap-4 border-y border-neutral-5 bg-neutral-2 px-2 py-2"
        data-test-id="threats-view-options"
      >
        <div className="flex items-center gap-3">
          <Text size="1">{t('threats.sort.label')}</Text>

          <Select.Root
            size="1"
            value={cardsSortBy}
            onValueChange={(value: SortByOptions) => {
              setCardsSortBy(value);
            }}
          >
            <Select.Trigger className="w-40" />
            <Select.Content>
              <Select.Group>
                <Select.Item value="riskScoreDesc">{t('threats.sort.options.risk_score_desc')}</Select.Item>
                <Select.Item value="timeDesc">{t('threats.sort.options.time_desc')}</Select.Item>
                <Select.Item value="timeAsc">{t('threats.sort.options.time_asc')}</Select.Item>
                <Select.Item value="lastEvidenceDesc">{t('threats.sort.options.last_evidence_desc')}</Select.Item>
                <Select.Item value="lastEvidenceAsc">{t('threats.sort.options.last_evidence_asc')}</Select.Item>
              </Select.Group>
            </Select.Content>
          </Select.Root>
        </div>

        <div className="flex gap-2">
          <ViewChangeButton
            isActive={viewMode === 'list'}
            onClick={() => {
              handleChangeViewMode('list');
            }}
          >
            <ListBulletIcon />
            {t('threats.list.view.list')}
          </ViewChangeButton>
          <ViewChangeButton
            isActive={viewMode === 'cards'}
            onClick={() => {
              handleChangeViewMode('cards');
            }}
          >
            <GridIcon />
            {t('threats.list.view.cards')}
          </ViewChangeButton>
        </div>
      </div>

      <ScrollArea
        style={{ height: 'calc(100vh - 332px)' }}
        className="bg-neutral-2"
        ref={scrollAreaRef}
        data-test-id="threats-container"
      >
        <>
          {viewMode === 'cards' ? (
            <div className="grid grid-cols-2 gap-4 p-4 xl:grid-cols-3" data-test-id="threats-cards-container">
              {threatsQuery.isError ? (
                <ErrorBadge className="col-span-2 xl:col-span-3" error={threatsQuery.error} />
              ) : threatsQuery.isLoading ? (
                <>
                  {[...Array<number>(pagination.pageSize)].map((_, index) => (
                    <ThreatCard key={index} isLoading={threatsQuery.isLoading} threat={LOADING_THREATS[0]!} />
                  ))}
                </>
              ) : threatsQuery.data?.data.length === 0 ? (
                <NoDataBadge className="col-span-2 xl:col-span-3" />
              ) : (
                threatsQuery.data?.data.map((threat) => <ThreatCard key={threat.id} threat={threat} />)
              )}
            </div>
          ) : (
            <ThreatsTable
              error={threatsQuery.error}
              isLoading={threatsQuery.isLoading}
              threats={threatsQuery.data?.data || NO_THREATS_ARRAY}
            />
          )}
        </>
      </ScrollArea>

      <div className="flex-1" />

      <PaginationControlPanel
        pageSize={pagination.pageSize}
        onPageSizeChange={handlePageSizeChange}
        canPrevious={canPreviousPage}
        canNext={threatsQuery.data?.metadata.hasMore ?? false}
        onPrevious={previousPage}
        onNext={nextPage}
        isLoading={threatsQuery.isLoading}
        isFetching={threatsQuery.isFetching}
        totalCount={totalCount!}
        pageIndex={pagination.pageIndex}
        onDataReload={onRefreshData}
      />
    </div>
  );
};
