import { useEffect, useMemo } from 'react';
import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  type Node,
  type Edge,
  ReactFlowProvider,
  MarkerType,
  useReactFlow,
  ConnectionMode,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';

import { useStore } from 'services/store';
import { LOADING_THREATS } from 'mocks/threat';
import type { components } from 'types/schemas/api-schema';

import { useAutoLayout } from './useAutoLayout';
import { ThreatEntityNode, type ThreatEntityNodeProps } from './ThreatEntityNode';
import { ThreatEvidenceNode, type ThreatEvidenceNodeProps } from './ThreatEvidenceNode';

const DEFAULT_EDGE_OPTIONS = {
  type: 'simplebezier',
  markerEnd: { type: MarkerType.ArrowClosed },
  pathOptions: { offset: 5 },
};

type ThreatNode = {
  position: { x: number; y: number };
  type: 'threatEntityNode' | 'threatEvidenceNode';
} & (ThreatEntityNodeProps | ThreatEvidenceNodeProps) &
  Omit<Node, 'data'>;

function constructNodeAndEdges(threat: components['schemas']['threat'], isLoading = false) {
  const nodes: ThreatNode[] = [];
  const edges: Edge[] = [];

  for (const evidence of threat.evidence) {
    for (const observation of evidence.observations) {
      for (const perception of observation.perceptions) {
        if (!perception.entities || perception.entities.length < 2) {
          continue;
        }

        const srcEntity = perception.entities[0]!;
        const dstEntity = perception.entities[1]!;

        const addEntityNode = (entity: components['schemas']['threatEntity']) => {
          nodes.push({
            id: entity.value,
            position: { x: 0, y: 0 },
            data: { entity: entity.value, geo: entity.geo, isLoading },
            type: 'threatEntityNode',
          });
        };

        const getOperatorNodeId = (name: string) => {
          return name + srcEntity.value + dstEntity.value;
        };

        const addOperatorNode = ({
          name,
          observations,
        }: {
          name: string;
          observations: { name: string; description: string; count: number }[];
        }) => {
          nodes.push({
            id: getOperatorNodeId(name),
            position: { x: 0, y: 0 },
            data: { name, observations, isLoading },
            type: 'threatEvidenceNode',
          });
        };

        if (!nodes.find((node) => node.id === srcEntity.value)) {
          addEntityNode(srcEntity);
        }

        if (!nodes.find((node) => node.id === dstEntity.value)) {
          addEntityNode(dstEntity);
        }

        let existingOperatorNode = nodes.find((node) => node.id === getOperatorNodeId(evidence.name));

        if (!existingOperatorNode) {
          addOperatorNode({
            name: evidence.name,
            observations: [],
          });

          existingOperatorNode = nodes.find((node) => node.id === getOperatorNodeId(evidence.name));
        }

        const existingObservation = (existingOperatorNode?.data as ThreatEvidenceNodeProps['data']).observations.find(
          (o) => o.name === observation.name,
        );

        if (existingObservation) {
          existingObservation.count++;
        } else {
          (existingOperatorNode?.data as ThreatEvidenceNodeProps['data']).observations.push({
            name: observation.name,
            description: observation.description,
            count: 1,
          });
        }

        const getEdgeKey = (side: 'src' | 'dest') => {
          return side + evidence.name + srcEntity.value + dstEntity.value;
        };

        if (!edges.find((edge) => edge.id === getEdgeKey('src'))) {
          edges.push({
            id: getEdgeKey('src'),
            source: srcEntity.value,
            target: getOperatorNodeId(evidence.name),
          });
        }

        if (!edges.find((edge) => edge.id === getEdgeKey('dest'))) {
          edges.push({
            id: getEdgeKey('dest'),
            source: getOperatorNodeId(evidence.name),
            target: dstEntity.value,
          });
        }
      }
    }
  }

  return { nodes, edges };
}

const NODE_TYPES = {
  threatEntityNode: ThreatEntityNode,
  threatEvidenceNode: ThreatEvidenceNode,
};

const LAYOUT_OPTIONS = {
  direction: 'LR' as const,
  spacing: [30, 50] as [number, number],
};

const PRO_OPTIONS = {
  hideAttribution: true,
};

function ThreatGraphContent({ isLoading, threat }: { isLoading: boolean; threat?: components['schemas']['threat'] }) {
  const theme = useStore((s) => s.theme.theme);
  const { fitView } = useReactFlow();

  const initialData = useMemo(() => {
    if (isLoading) {
      return constructNodeAndEdges(LOADING_THREATS[0]!, isLoading);
    }
    if (!threat) {
      return {
        nodes: [],
        edges: [],
      };
    }

    return constructNodeAndEdges(threat);
  }, [isLoading, threat]);

  const [nodes, setNodes, onNodesChange] = useNodesState(initialData.nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialData.edges);

  useAutoLayout(LAYOUT_OPTIONS);

  // TODO remove this once example threat is removed
  useEffect(() => {
    setNodes(initialData.nodes);
    setEdges(initialData.edges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData]);

  useEffect(() => {
    void fitView({ padding: 0.2 });
  }, [fitView, nodes]);

  return (
    <div
      className="h-[350px] bg-neutral-1"
      style={
        {
          '--xy-handle-background-color': 'transparent',
          '--xy-handle-border-color': 'transparent',
        } as React.CSSProperties
      }
    >
      <ReactFlow
        colorMode={theme}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        defaultEdgeOptions={DEFAULT_EDGE_OPTIONS}
        fitView
        nodesDraggable={false}
        nodesConnectable={false}
        nodesFocusable={false}
        edgesFocusable={false}
        elementsSelectable={false}
        panOnDrag={false}
        panOnScroll={false}
        zoomOnScroll={false}
        zoomOnDoubleClick={false}
        zoomOnPinch={false}
        nodeTypes={NODE_TYPES}
        proOptions={PRO_OPTIONS}
        connectionMode={ConnectionMode.Strict}
      />
    </div>
  );
}

export function ThreatGraph({ threat, isLoading }: { threat?: components['schemas']['threat']; isLoading: boolean }) {
  return (
    <ReactFlowProvider>
      <ThreatGraphContent isLoading={isLoading} threat={threat} />
    </ReactFlowProvider>
  );
}
