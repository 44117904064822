import deepmerge from 'deepmerge';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import type { DeepPartial } from 'types/helpers';

import { i18nSlice, type I18nSlice } from './slices/i18n';
import { menuSlice, type MenuSlice } from './slices/menu';
import { themeSlice, type ThemeSlice } from './slices/theme';
import { authSlice, type AuthSlice } from './slices/auth';
import { threatsSlice, type ThreatsSlice } from './slices/threats';
import { lastPageSearchSlice, type LastPageSearchSlice } from './slices/lastPageSearch';
import { dashboardConfigurationSlice, type DashboardConfigurationSlice } from './slices/dashboardConfiguration';

const LOCAL_STORAGE_KEY = 'mixmode';

const slices = [
  i18nSlice,
  themeSlice,
  menuSlice,
  authSlice,
  threatsSlice,
  lastPageSearchSlice,
  dashboardConfigurationSlice,
];

export type AllSlices = I18nSlice &
  ThemeSlice &
  MenuSlice &
  AuthSlice &
  ThreatsSlice &
  LastPageSearchSlice &
  DashboardConfigurationSlice;

export const useStore = create<AllSlices>()(
  devtools(
    persist(
      (...args) => {
        return {
          ...slices.reduce(
            (acc, slice) => ({
              ...acc,
              ...slice.createSlice(...args),
            }),
            {} as AllSlices,
          ),
        };
      },
      {
        name: LOCAL_STORAGE_KEY,
        merge: (persistedState, current) =>
          deepmerge(current, persistedState as DeepPartial<AllSlices>, {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            arrayMerge: (_, sourceArray) => sourceArray,
          }) as AllSlices,
        partialize: (store) => {
          const partial = {} as AllSlices;

          for (const slice of slices) {
            if (slice.persist) {
              Object.assign(partial, slice.persist(store));
            }
          }

          return partial;
        },
        onRehydrateStorage: () => (state) => {
          if (state?.theme?.theme === 'light') {
            state.theme.theme = 'dark';

            const storedData = localStorage.getItem(LOCAL_STORAGE_KEY);
            if (storedData) {
              const parsed = JSON.parse(storedData) as AllSlices;
              parsed.theme.theme = 'dark';
              localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(parsed));
            }
          }
        },
      },
    ),
  ),
);
