import {
  PieChart as RPieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
  type TooltipProps as RTooltipProps,
} from 'recharts';

import { cn } from 'utils/styles';
import { compactNumber } from 'utils/numbers';

import { useTranslate } from 'services/i18n/useTranslate';

import { LayersIcon } from 'components/common/Icons';
import { Button } from 'components/common/Button';

import { WidgetContainer } from './common/components/WidgetContainer';
import { TooltipContainer } from './common/components/TooltipContainer';
import { CHART_HEIGHT } from './common/consts';

const COLOR = ['var(--info-a8)', 'var(--info-a10)'];

type PieChartDataPoint = {
  name: string;
  value: number;
};

const data: PieChartDataPoint[] = [
  { name: 'Tenant A', value: 45000 },
  { name: 'Tenant B', value: 15000 },
];

type TooltipProps = RTooltipProps<string, number>;

const CustomTooltip = ({ active, payload }: TooltipProps) => {
  if (!active || !payload?.length) {
    return null;
  }

  const data = payload?.[0]?.payload as PieChartDataPoint | undefined;
  if (!data) {
    return null;
  }

  return (
    <TooltipContainer>
      <ul>
        {payload.map((item) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const color = item.payload?.fill as string;
          return typeof item.value === 'number' ? (
            <li key={item.dataKey} className="flex items-center gap-2 text-text">
              <div className="size-4" style={{ backgroundColor: color }} />
              {item.name}: {compactNumber(item.value)}
            </li>
          ) : null;
        })}
      </ul>
    </TooltipContainer>
  );
};

export function TotalEventsByTenant() {
  const t = useTranslate();

  const isLoading = false;
  const queryIsError = false;
  if (queryIsError) {
    return (
      <WidgetContainer
        title={t('reports.statistics.widgets.total_events_by_tenant.title')}
        headerLeft={<LayersIcon />}
        className="h-80"
      >
        {t('common.errors.error_loading_piechart')}
      </WidgetContainer>
    );
  }

  const hasNoData = false;
  if (hasNoData) {
    return (
      <WidgetContainer
        title={t('reports.statistics.widgets.total_events_by_tenant.title')}
        headerLeft={<LayersIcon />}
        className="h-80"
      >
        {t('common.errors.no_data')}
      </WidgetContainer>
    );
  }

  return (
    <WidgetContainer
      title={t('reports.statistics.widgets.total_events_by_tenant.title')}
      headerLeft={<LayersIcon />}
      className="h-80"
      headerRight={
        <div className="flex items-center gap-4">
          <Button variant="outline" size="1" color="gray">
            {t('reports.statistics.widgets.total_events_by_tenant.buttons.day')}
          </Button>
          <Button variant="ghost" size="1" color="gray">
            {t('reports.statistics.widgets.total_events_by_tenant.buttons.month')}
          </Button>
        </div>
      }
    >
      <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
        <RPieChart
          margin={{
            top: 4,
            right: 8,
            left: 8,
            bottom: 4,
          }}
        >
          <Pie
            data={data}
            nameKey="name"
            dataKey="value"
            cx="30%"
            cy="50%"
            innerRadius={70}
            outerRadius={90}
            startAngle={90}
            endAngle={-270}
            isAnimationActive={!isLoading}
            stroke="none"
          >
            {data.map((_, index) => {
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={COLOR[index % COLOR.length]}
                  className={cn(isLoading && 'animate-skeleton-fill-pulse')}
                />
              );
            })}
          </Pie>

          <Tooltip content={<CustomTooltip />} />

          {!isLoading && (
            <Legend
              layout="vertical"
              verticalAlign="middle"
              align="center"
              wrapperStyle={{
                marginLeft: '80px',
              }}
              formatter={(value, entry) => (
                <div className="ml-1 inline-flex gap-3 text-sm text-neutral-12">
                  <span>{value}</span>
                  <span>{compactNumber(entry.payload?.value as number)}</span>
                </div>
              )}
            />
          )}
        </RPieChart>
      </ResponsiveContainer>
    </WidgetContainer>
  );
}
