import { type Slice } from './_utils';

type ThemeOptions = 'light' | 'dark';

export type ThemeSlice = {
  theme: {
    theme: ThemeOptions;
    changeTheme: (theme: ThemeOptions) => void;
  };
};

export const themeSlice: Slice<ThemeSlice> = {
  createSlice: (set) => ({
    theme: {
      theme: 'dark',
      changeTheme: (theme) =>
        set((prev) => ({
          theme: {
            ...prev.theme,
            theme,
          },
        })),
    },
  }),
  persist: (state) => ({
    theme: {
      theme: state.theme.theme,
    },
  }),
};
