import { createContext, memo, useContext, useEffect, useId, useRef, useState, type ComponentProps } from 'react';
import * as RadixTabs from '@radix-ui/react-tabs';
import { AnimatePresence, motion } from 'framer-motion';

import { cn } from 'utils/styles';
import { Text } from 'components/common/Text';

type TabType = 'standard' | 'enclosed';
type TabSize = '1' | '2';

const TabContext = createContext<{
  size: TabSize;
  type: TabType;
  tabId: string;
  isTransparent?: boolean;
}>({
  size: '1' as TabSize,
  type: 'standard',
  tabId: 'tabId',
});

export const Tabs = ({
  size,
  type,
  isTransparent,
  ...props
}: ComponentProps<typeof RadixTabs.Root> & {
  type?: TabType;
  size?: TabSize;
  isTransparent?: boolean;
}) => {
  const tabId = useId();

  return (
    <TabContext.Provider
      value={{
        size: size || '1',
        type: type || 'standard',
        tabId,
        isTransparent,
      }}
    >
      <RadixTabs.Root {...props} />
    </TabContext.Provider>
  );
};

export const TabsList = RadixTabs.List;

export const TabContent = RadixTabs.Content;

export const TabTrigger = memo(({ children, className, ...props }: ComponentProps<typeof RadixTabs.Trigger>) => {
  const { size, tabId, type, isTransparent } = useContext(TabContext);
  const [isActive, setIsActive] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const isTabActive = ref.current?.dataset?.state === 'active';

    setIsActive(isTabActive);

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'data-state') {
          const isTabActive = ref.current?.dataset?.state === 'active';

          setIsActive(isTabActive);
        }
      });
    });

    observer.observe(ref.current as Node, {
      attributes: true,
      childList: false,
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <RadixTabs.Trigger
      ref={ref}
      className={cn(
        'group relative cursor-pointer border-0 border-solid border-neutral-6 px-2 py-1.5 outline-none',
        {
          'bg-neutral-a4': (!isActive && type === 'enclosed') || isTransparent,
          'bg-translucent': isActive && type === 'enclosed',
          'bg-transparent': type === 'standard' || (isActive && isTransparent),
          'border-l py-2 last:border-r first-of-type:border-l-0': type === 'enclosed',
          'border-t-0': isTransparent,
        },
        className,
      )}
      {...props}
    >
      {/* Focus ring */}
      <div className="absolute bottom-1 left-0 top-1 w-full overflow-clip rounded-1 border-2 border-solid border-accent-a8 opacity-0 group-focus-visible:opacity-100">
        <div className="absolute bottom-0 left-0 top-0 w-full border-2 border-solid border-accent-3" />
      </div>

      {/* Content */}
      <div
        className={cn('rounded-1 px-2', {
          'group-hover:bg-neutral-3': type === 'standard',
          'py-0.5': (type === 'enclosed' && size === '2') || (type === 'standard' && size === '1'),
          'py-1': type === 'standard' && size === '2',
        })}
      >
        <Text
          weight="medium"
          className={cn(
            'text-neutral-a11 group-disabled:text-neutral-8 group-data-[state=active]:text-neutral-12',
            {
              'text-xs': size === '1',
              'text-sm': size === '2',
            },
            className,
          )}
        >
          {children}
        </Text>
      </div>

      {/* Top/bottom border */}
      <div
        className={cn('absolute left-0 h-px w-full bg-neutral-6', {
          'bottom-0': type === 'standard',
          'top-0': type === 'enclosed',
          'bg-transparent': isTransparent,
        })}
      />

      {/* Active border */}
      <AnimatePresence>
        {isActive && (
          <motion.div
            layoutId={tabId}
            className={cn('absolute bottom-0 left-0 z-10 h-0.5 w-full bg-orange-10', {
              'bottom-0': type === 'standard',
              'top-0': type === 'enclosed',
            })}
            transition={{ type: 'spring', bounce: 0.1, duration: 0.3 }}
          />
        )}
      </AnimatePresence>
    </RadixTabs.Trigger>
  );
});
