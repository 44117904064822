import { useCanGoBack, useRouter } from '@tanstack/react-router';
import { ArrowLeftIcon, HamburgerMenuIcon } from '@radix-ui/react-icons';

import { IconButton } from 'components/common/IconButton';
import mixmodeLogo from 'static/images/mixmode-logo-new.svg';
import type { FlattenedTranslationKeys } from 'types/translations';
import { PageHeaderActions } from 'components/common/PageHeaderActions';

export type MetaProps = {
  name: string;
  content: FlattenedTranslationKeys;
};

export function PageHeader({ onToggleMenu }: { onToggleMenu?: () => void }) {
  const router = useRouter();
  const canGoBack = useCanGoBack();

  // const matches = useMatches();

  // const { title, description } = useMemo(() => {
  //   const reversedMatches = [...matches].reverse();
  //   let meta: MetaProps[] | undefined = undefined;

  //   for (const match of reversedMatches) {
  //     if (match.meta && Array.isArray(match.meta)) {
  //       meta = match.meta as MetaProps[];
  //       break;
  //     }
  //   }

  //   const pageTitle = meta?.find((item) => item.name === 'pageTitle')?.content;
  //   const pageDescription = meta?.find((item) => item.name === 'pageDescription')?.content;

  //   return {
  //     title: pageTitle ? t(pageTitle) : '',
  //     description: pageDescription ? t(pageDescription) : '',
  //   };
  // }, [matches, t]);

  return (
    <div className="fixed left-0 top-0 z-[100] mb-2 w-full bg-[#1c1c1c] px-2 pt-2">
      <div className="flex w-full items-center justify-between rounded-[4px] bg-[#2c2c2c] px-2">
        <div className="flex items-center gap-8 pl-1">
          {onToggleMenu && (
            <IconButton size="4" onClick={onToggleMenu} variant="ghost">
              <HamburgerMenuIcon className="stroke-text" />
            </IconButton>
          )}

          <IconButton disabled={!canGoBack} size="4" variant="ghost" color="gray" onClick={() => router.history.back()}>
            <ArrowLeftIcon />
          </IconButton>

          <img src={mixmodeLogo} alt="MixMode" className="-ml-4 h-[56px]" />
        </div>

        <PageHeaderActions />
      </div>
    </div>
  );
}
