import { type Slice } from './_utils';

export type DashboardConfigurationSlice = {
  dashboardConfigurationSlice: {
    areThreatsFacetsOpen: boolean;
    areAnomaliesFacetsOpen: boolean;

    toggleThreatsFacets: () => void;
    toggleAnomaliesFacets: () => void;
  };
};

export const dashboardConfigurationSlice: Slice<DashboardConfigurationSlice> = {
  createSlice: (set) => ({
    dashboardConfigurationSlice: {
      areThreatsFacetsOpen: true,
      areAnomaliesFacetsOpen: true,

      toggleThreatsFacets: () => {
        return set((prev) => {
          return {
            ...prev,
            dashboardConfigurationSlice: {
              ...prev.dashboardConfigurationSlice,
              areThreatsFacetsOpen: !prev.dashboardConfigurationSlice.areThreatsFacetsOpen,
            },
          };
        });
      },
      toggleAnomaliesFacets: () => {
        return set((prev) => {
          return {
            ...prev,
            dashboardConfigurationSlice: {
              ...prev.dashboardConfigurationSlice,
              areAnomaliesFacetsOpen: !prev.dashboardConfigurationSlice.areAnomaliesFacetsOpen,
            },
          };
        });
      },
    },
  }),
};
