import { createFileRoute } from '@tanstack/react-router';

import { ThreatsScene } from 'components/scenes/Threats';

export const Route = createFileRoute('/_app/threats/')({
  component: ThreatsScene,
  head: () => ({
    meta: [
      {
        name: 'pageTitle',
        content: 'threats.page_title',
      },
      {
        name: 'metaTitle',
        content: 'threats.page_title',
      },
      {
        name: 'pageDescription',
        content: 'threats.page_description',
      },
    ],
  }),
});
