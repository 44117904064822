import { createContext, useCallback, useContext, useState, type ReactNode } from 'react';
import { useNavigate } from '@tanstack/react-router';
import type { Updater, PaginationState } from '@tanstack/react-table';

export type AnomaliesFilterProperty = 'confidenceScore' | 'dimension' | 'tenantId' | 'pipelineId' | 'entity';

type HandleFilterClick = (property: AnomaliesFilterProperty, value: string) => void;

type AnomaliesContextProps = {
  addFilter: HandleFilterClick;
  pagination: PaginationState;
  setPagination: (updater: Updater<PaginationState>) => void;
};

const AnomaliesContext = createContext<AnomaliesContextProps>({} as AnomaliesContextProps);

// eslint-disable-next-line react-refresh/only-export-components
export const useAnomaliesContext = () => {
  const context = useContext(AnomaliesContext);
  if (!context) {
    throw new Error('useAnomaliesContext must be used within a AnomaliesProvider');
  }
  return context;
};

type AnomaliesProviderProps = {
  children: ReactNode;
};

export const AnomaliesProvider = ({ children }: AnomaliesProviderProps) => {
  const navigate = useNavigate({ from: '/detections' });
  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 10 });

  const addFilter: HandleFilterClick = useCallback(
    (property, value) => {
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));

      void navigate({
        search: (prev) => {
          const currentVal = prev?.[property] || [];

          if (currentVal.includes(value)) {
            const newVal = currentVal.filter((val) => val !== value);
            return {
              ...(prev || {}),
              [property]: newVal.length ? newVal : undefined,
              anomalyId: undefined,
            };
          }
          return {
            ...(prev || {}),
            [property]: [...currentVal, value],
            anomalyId: undefined,
          };
        },
      });
    },
    [navigate],
  );

  return (
    <AnomaliesContext.Provider value={{ addFilter, pagination, setPagination }}>{children}</AnomaliesContext.Provider>
  );
};
