import { getRouteApi } from '@tanstack/react-router';
import { format } from 'date-fns';

import { useStore } from 'services/store';
import { useTenants } from 'services/api/tenants';
import { usePipelines } from 'services/api/pipelines';
import { useThreatsParams } from 'services/api/threats';
import { useTranslate } from 'services/i18n/useTranslate';
import { useGetCallerDisplayName } from 'services/api/callers';
import { dateTimeFormatShort } from 'consts/dateFormats';

import { Badge } from 'components/common/Badge';
import { Skeleton } from 'components/common/Skeleton';
import { IconButton } from 'components/common/IconButton';
import { Cross1Icon, LayersIcon, MixerHorizontalIcon } from 'components/common/Icons';

import {
  ThreatAssigneeBadge,
  ThreatDimensionBadge,
  ThreatEntityBadge,
  ThreatMitreBadge,
  ThreatGeoBadge,
  ThreatRiskScoreBadge,
  ThreatStatusBadge,
  ThreatWorkflowStatusBadge,
} from '../common/ThreatsBadges';
import { useGetDisplayValue } from '../common/useGetDisplayValue';
import { useThreatsContext } from '../ThreatsContext';
import { SaveFiltersButton } from './SaveFiltersButton';

const Route = getRouteApi('/_app/threats');

export const FilterBar = () => {
  const t = useTranslate();
  const searchParams = Route.useSearch();
  const navigate = Route.useNavigate();
  const threatsParams = useThreatsParams();
  const tenantsQuery = useTenants();
  const pipelinesQuery = usePipelines();
  const { toggleFilter } = useThreatsContext();

  const areThreatsFacetsOpen = useStore((s) => s.dashboardConfigurationSlice.areThreatsFacetsOpen);

  const getDisplayValue = useGetDisplayValue();

  const { getCallerDisplayName } = useGetCallerDisplayName();

  const isLoading = tenantsQuery.isLoading || pipelinesQuery.isLoading;

  const combinedParams = [
    ...(searchParams.riskScore || []),
    ...(searchParams.status || []),
    ...(searchParams.entity || []),
    ...(searchParams.mitreId || []),
    ...(searchParams.dimension || []),
    ...(searchParams.pipelineId || []),
    ...(searchParams.tenantId || []),
    ...(searchParams.geo || []),
    ...(searchParams.workflowAssignedTo || []),
    ...(searchParams.workflowStatus || []),
  ];

  const hasActiveFilters = !!combinedParams.length;

  const clearAllFilters = () => {
    void navigate({
      search: (prev) => {
        const { timeRange, startTime, endTime } = prev;

        if (startTime && endTime) {
          return {
            startTime,
            endTime,
          };
        }

        return {
          timeRange,
        };
      },
    });
  };

  return (
    <div className="my-2 mr-2 flex gap-2">
      <div
        className="relative flex min-h-8 flex-1 flex-wrap gap-2 rounded-1 border border-neutral-a7 bg-accent-a1 py-1 pl-2 pr-8"
        data-test-id="threats-filter-bar"
      >
        {!areThreatsFacetsOpen && (
          <Badge>
            {t('common.time_value', {
              value:
                threatsParams.timeRange ||
                (threatsParams.startTime &&
                  threatsParams.endTime &&
                  `${format(threatsParams.startTime, dateTimeFormatShort)} - ${format(threatsParams.endTime, dateTimeFormatShort)}`),
            })}
          </Badge>
        )}

        {searchParams.riskScore?.map((value) => (
          <ThreatRiskScoreBadge
            key={value}
            isActive
            riskScoreRange={value}
            onClick={() => toggleFilter('riskScore', value)}
          />
        ))}

        {threatsParams.tenantId?.map((value) => (
          <Skeleton key={value} isLoading={isLoading}>
            <Badge
              onClick={() => toggleFilter('tenantId', value)}
              className={'hover:cursor-pointer hover:bg-gray-6'}
              color="gray"
            >
              <LayersIcon />
              {getDisplayValue('tenantId', value)}
              <Cross1Icon className="h-3 w-3" />
            </Badge>
          </Skeleton>
        ))}

        {threatsParams.pipelineId?.map((value) => (
          <Skeleton key={value} isLoading={isLoading}>
            <Badge
              onClick={() => toggleFilter('pipelineId', value)}
              className={'hover:cursor-pointer hover:bg-brown-6'}
              color="brown"
            >
              <MixerHorizontalIcon />
              {getDisplayValue('pipelineId', value)}
              <Cross1Icon className="h-3 w-3" />
            </Badge>
          </Skeleton>
        ))}

        {threatsParams.workflowStatus?.map((value) => (
          <ThreatWorkflowStatusBadge
            key={value}
            isActive
            workflowStatus={value}
            onClick={() => toggleFilter('workflowStatus', value)}
          />
        ))}

        {threatsParams.workflowAssignedTo?.map((value) => (
          <ThreatAssigneeBadge key={value} isActive onClick={() => toggleFilter('workflowAssignedTo', String(value))}>
            {getCallerDisplayName(value)}
          </ThreatAssigneeBadge>
        ))}

        {threatsParams.entity?.map((value) => (
          <ThreatEntityBadge key={value} isActive onClick={() => toggleFilter('entity', value)} entity={{ value }} />
        ))}

        {threatsParams.mitreId?.map((value) => (
          <ThreatMitreBadge key={value} isActive onClick={() => toggleFilter('mitreId', value)}>
            {value}
          </ThreatMitreBadge>
        ))}

        {threatsParams.geo?.map((value) => (
          <Skeleton key={value} isLoading={isLoading}>
            <ThreatGeoBadge geo={value} isActive onClick={() => toggleFilter('geo', value)} />
          </Skeleton>
        ))}

        {threatsParams.status?.map((value) => (
          <ThreatStatusBadge key={value} isActive status={value} onClick={() => toggleFilter('status', value)} />
        ))}

        {threatsParams.dimension?.map((value) => (
          <ThreatDimensionBadge key={value} isActive onClick={() => toggleFilter('dimension', value)}>
            {value}
          </ThreatDimensionBadge>
        ))}

        {hasActiveFilters && (
          <IconButton
            size="1"
            variant="ghost"
            color="gray"
            className="absolute right-2 top-2"
            aria-label={t('threats.saved_filters.clear_all')}
            onClick={clearAllFilters}
          >
            <Cross1Icon />
          </IconButton>
        )}
      </div>

      <div className="mt-1">
        <SaveFiltersButton isDisabled={!hasActiveFilters} />
      </div>
    </div>
  );
};
