/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as AuthImport } from './routes/_auth'
import { Route as AppImport } from './routes/_app'
import { Route as AppIndexImport } from './routes/_app/index'
import { Route as AuthResetPasswordImport } from './routes/_auth/reset-password'
import { Route as AuthCreateAccountImport } from './routes/_auth/create-account'
import { Route as AppThreatsImport } from './routes/_app/threats'
import { Route as AppSettingsImport } from './routes/_app/settings'
import { Route as AppReportsImport } from './routes/_app/reports'
import { Route as AppDetectionsImport } from './routes/_app/detections'
import { Route as AppThreatsIndexImport } from './routes/_app/threats/index'
import { Route as AppSettingsIndexImport } from './routes/_app/settings/index'
import { Route as AppThreatsThreatIdImport } from './routes/_app/threats/$threatId'
import { Route as AppSettingsUsersAndAccessImport } from './routes/_app/settings/users-and-access'
import { Route as AppSettingsPlatformImport } from './routes/_app/settings/platform'
import { Route as AppSettingsEnrichmentsImport } from './routes/_app/settings/enrichments'
import { Route as AppSettingsUsersAndAccessUsersImport } from './routes/_app/settings/users-and-access/users'
import { Route as AppSettingsUsersAndAccessRolesImport } from './routes/_app/settings/users-and-access/roles'
import { Route as AppSettingsPlatformTenantsImport } from './routes/_app/settings/platform/tenants'
import { Route as AppSettingsPlatformPipelinesImport } from './routes/_app/settings/platform/pipelines'
import { Route as AppSettingsEnrichmentsPlatformImport } from './routes/_app/settings/enrichments/platform'
import { Route as AppSettingsEnrichmentsCustomImport } from './routes/_app/settings/enrichments/custom'

// Create/Update Routes

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const AppRoute = AppImport.update({
  id: '/_app',
  getParentRoute: () => rootRoute,
} as any)

const AppIndexRoute = AppIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AppRoute,
} as any)

const AuthResetPasswordRoute = AuthResetPasswordImport.update({
  id: '/reset-password',
  path: '/reset-password',
  getParentRoute: () => AuthRoute,
} as any)

const AuthCreateAccountRoute = AuthCreateAccountImport.update({
  id: '/create-account',
  path: '/create-account',
  getParentRoute: () => AuthRoute,
} as any)

const AppThreatsRoute = AppThreatsImport.update({
  id: '/threats',
  path: '/threats',
  getParentRoute: () => AppRoute,
} as any)

const AppSettingsRoute = AppSettingsImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => AppRoute,
} as any)

const AppReportsRoute = AppReportsImport.update({
  id: '/reports',
  path: '/reports',
  getParentRoute: () => AppRoute,
} as any)

const AppDetectionsRoute = AppDetectionsImport.update({
  id: '/detections',
  path: '/detections',
  getParentRoute: () => AppRoute,
} as any)

const AppThreatsIndexRoute = AppThreatsIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AppThreatsRoute,
} as any)

const AppSettingsIndexRoute = AppSettingsIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AppSettingsRoute,
} as any)

const AppThreatsThreatIdRoute = AppThreatsThreatIdImport.update({
  id: '/$threatId',
  path: '/$threatId',
  getParentRoute: () => AppThreatsRoute,
} as any)

const AppSettingsUsersAndAccessRoute = AppSettingsUsersAndAccessImport.update({
  id: '/users-and-access',
  path: '/users-and-access',
  getParentRoute: () => AppSettingsRoute,
} as any)

const AppSettingsPlatformRoute = AppSettingsPlatformImport.update({
  id: '/platform',
  path: '/platform',
  getParentRoute: () => AppSettingsRoute,
} as any)

const AppSettingsEnrichmentsRoute = AppSettingsEnrichmentsImport.update({
  id: '/enrichments',
  path: '/enrichments',
  getParentRoute: () => AppSettingsRoute,
} as any)

const AppSettingsUsersAndAccessUsersRoute =
  AppSettingsUsersAndAccessUsersImport.update({
    id: '/users',
    path: '/users',
    getParentRoute: () => AppSettingsUsersAndAccessRoute,
  } as any)

const AppSettingsUsersAndAccessRolesRoute =
  AppSettingsUsersAndAccessRolesImport.update({
    id: '/roles',
    path: '/roles',
    getParentRoute: () => AppSettingsUsersAndAccessRoute,
  } as any)

const AppSettingsPlatformTenantsRoute = AppSettingsPlatformTenantsImport.update(
  {
    id: '/tenants',
    path: '/tenants',
    getParentRoute: () => AppSettingsPlatformRoute,
  } as any,
)

const AppSettingsPlatformPipelinesRoute =
  AppSettingsPlatformPipelinesImport.update({
    id: '/pipelines',
    path: '/pipelines',
    getParentRoute: () => AppSettingsPlatformRoute,
  } as any)

const AppSettingsEnrichmentsPlatformRoute =
  AppSettingsEnrichmentsPlatformImport.update({
    id: '/platform',
    path: '/platform',
    getParentRoute: () => AppSettingsEnrichmentsRoute,
  } as any)

const AppSettingsEnrichmentsCustomRoute =
  AppSettingsEnrichmentsCustomImport.update({
    id: '/custom',
    path: '/custom',
    getParentRoute: () => AppSettingsEnrichmentsRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_app': {
      id: '/_app'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AppImport
      parentRoute: typeof rootRoute
    }
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/_app/detections': {
      id: '/_app/detections'
      path: '/detections'
      fullPath: '/detections'
      preLoaderRoute: typeof AppDetectionsImport
      parentRoute: typeof AppImport
    }
    '/_app/reports': {
      id: '/_app/reports'
      path: '/reports'
      fullPath: '/reports'
      preLoaderRoute: typeof AppReportsImport
      parentRoute: typeof AppImport
    }
    '/_app/settings': {
      id: '/_app/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof AppSettingsImport
      parentRoute: typeof AppImport
    }
    '/_app/threats': {
      id: '/_app/threats'
      path: '/threats'
      fullPath: '/threats'
      preLoaderRoute: typeof AppThreatsImport
      parentRoute: typeof AppImport
    }
    '/_auth/create-account': {
      id: '/_auth/create-account'
      path: '/create-account'
      fullPath: '/create-account'
      preLoaderRoute: typeof AuthCreateAccountImport
      parentRoute: typeof AuthImport
    }
    '/_auth/reset-password': {
      id: '/_auth/reset-password'
      path: '/reset-password'
      fullPath: '/reset-password'
      preLoaderRoute: typeof AuthResetPasswordImport
      parentRoute: typeof AuthImport
    }
    '/_app/': {
      id: '/_app/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AppIndexImport
      parentRoute: typeof AppImport
    }
    '/_app/settings/enrichments': {
      id: '/_app/settings/enrichments'
      path: '/enrichments'
      fullPath: '/settings/enrichments'
      preLoaderRoute: typeof AppSettingsEnrichmentsImport
      parentRoute: typeof AppSettingsImport
    }
    '/_app/settings/platform': {
      id: '/_app/settings/platform'
      path: '/platform'
      fullPath: '/settings/platform'
      preLoaderRoute: typeof AppSettingsPlatformImport
      parentRoute: typeof AppSettingsImport
    }
    '/_app/settings/users-and-access': {
      id: '/_app/settings/users-and-access'
      path: '/users-and-access'
      fullPath: '/settings/users-and-access'
      preLoaderRoute: typeof AppSettingsUsersAndAccessImport
      parentRoute: typeof AppSettingsImport
    }
    '/_app/threats/$threatId': {
      id: '/_app/threats/$threatId'
      path: '/$threatId'
      fullPath: '/threats/$threatId'
      preLoaderRoute: typeof AppThreatsThreatIdImport
      parentRoute: typeof AppThreatsImport
    }
    '/_app/settings/': {
      id: '/_app/settings/'
      path: '/'
      fullPath: '/settings/'
      preLoaderRoute: typeof AppSettingsIndexImport
      parentRoute: typeof AppSettingsImport
    }
    '/_app/threats/': {
      id: '/_app/threats/'
      path: '/'
      fullPath: '/threats/'
      preLoaderRoute: typeof AppThreatsIndexImport
      parentRoute: typeof AppThreatsImport
    }
    '/_app/settings/enrichments/custom': {
      id: '/_app/settings/enrichments/custom'
      path: '/custom'
      fullPath: '/settings/enrichments/custom'
      preLoaderRoute: typeof AppSettingsEnrichmentsCustomImport
      parentRoute: typeof AppSettingsEnrichmentsImport
    }
    '/_app/settings/enrichments/platform': {
      id: '/_app/settings/enrichments/platform'
      path: '/platform'
      fullPath: '/settings/enrichments/platform'
      preLoaderRoute: typeof AppSettingsEnrichmentsPlatformImport
      parentRoute: typeof AppSettingsEnrichmentsImport
    }
    '/_app/settings/platform/pipelines': {
      id: '/_app/settings/platform/pipelines'
      path: '/pipelines'
      fullPath: '/settings/platform/pipelines'
      preLoaderRoute: typeof AppSettingsPlatformPipelinesImport
      parentRoute: typeof AppSettingsPlatformImport
    }
    '/_app/settings/platform/tenants': {
      id: '/_app/settings/platform/tenants'
      path: '/tenants'
      fullPath: '/settings/platform/tenants'
      preLoaderRoute: typeof AppSettingsPlatformTenantsImport
      parentRoute: typeof AppSettingsPlatformImport
    }
    '/_app/settings/users-and-access/roles': {
      id: '/_app/settings/users-and-access/roles'
      path: '/roles'
      fullPath: '/settings/users-and-access/roles'
      preLoaderRoute: typeof AppSettingsUsersAndAccessRolesImport
      parentRoute: typeof AppSettingsUsersAndAccessImport
    }
    '/_app/settings/users-and-access/users': {
      id: '/_app/settings/users-and-access/users'
      path: '/users'
      fullPath: '/settings/users-and-access/users'
      preLoaderRoute: typeof AppSettingsUsersAndAccessUsersImport
      parentRoute: typeof AppSettingsUsersAndAccessImport
    }
  }
}

// Create and export the route tree

interface AppSettingsEnrichmentsRouteChildren {
  AppSettingsEnrichmentsCustomRoute: typeof AppSettingsEnrichmentsCustomRoute
  AppSettingsEnrichmentsPlatformRoute: typeof AppSettingsEnrichmentsPlatformRoute
}

const AppSettingsEnrichmentsRouteChildren: AppSettingsEnrichmentsRouteChildren =
  {
    AppSettingsEnrichmentsCustomRoute: AppSettingsEnrichmentsCustomRoute,
    AppSettingsEnrichmentsPlatformRoute: AppSettingsEnrichmentsPlatformRoute,
  }

const AppSettingsEnrichmentsRouteWithChildren =
  AppSettingsEnrichmentsRoute._addFileChildren(
    AppSettingsEnrichmentsRouteChildren,
  )

interface AppSettingsPlatformRouteChildren {
  AppSettingsPlatformPipelinesRoute: typeof AppSettingsPlatformPipelinesRoute
  AppSettingsPlatformTenantsRoute: typeof AppSettingsPlatformTenantsRoute
}

const AppSettingsPlatformRouteChildren: AppSettingsPlatformRouteChildren = {
  AppSettingsPlatformPipelinesRoute: AppSettingsPlatformPipelinesRoute,
  AppSettingsPlatformTenantsRoute: AppSettingsPlatformTenantsRoute,
}

const AppSettingsPlatformRouteWithChildren =
  AppSettingsPlatformRoute._addFileChildren(AppSettingsPlatformRouteChildren)

interface AppSettingsUsersAndAccessRouteChildren {
  AppSettingsUsersAndAccessRolesRoute: typeof AppSettingsUsersAndAccessRolesRoute
  AppSettingsUsersAndAccessUsersRoute: typeof AppSettingsUsersAndAccessUsersRoute
}

const AppSettingsUsersAndAccessRouteChildren: AppSettingsUsersAndAccessRouteChildren =
  {
    AppSettingsUsersAndAccessRolesRoute: AppSettingsUsersAndAccessRolesRoute,
    AppSettingsUsersAndAccessUsersRoute: AppSettingsUsersAndAccessUsersRoute,
  }

const AppSettingsUsersAndAccessRouteWithChildren =
  AppSettingsUsersAndAccessRoute._addFileChildren(
    AppSettingsUsersAndAccessRouteChildren,
  )

interface AppSettingsRouteChildren {
  AppSettingsEnrichmentsRoute: typeof AppSettingsEnrichmentsRouteWithChildren
  AppSettingsPlatformRoute: typeof AppSettingsPlatformRouteWithChildren
  AppSettingsUsersAndAccessRoute: typeof AppSettingsUsersAndAccessRouteWithChildren
  AppSettingsIndexRoute: typeof AppSettingsIndexRoute
}

const AppSettingsRouteChildren: AppSettingsRouteChildren = {
  AppSettingsEnrichmentsRoute: AppSettingsEnrichmentsRouteWithChildren,
  AppSettingsPlatformRoute: AppSettingsPlatformRouteWithChildren,
  AppSettingsUsersAndAccessRoute: AppSettingsUsersAndAccessRouteWithChildren,
  AppSettingsIndexRoute: AppSettingsIndexRoute,
}

const AppSettingsRouteWithChildren = AppSettingsRoute._addFileChildren(
  AppSettingsRouteChildren,
)

interface AppThreatsRouteChildren {
  AppThreatsThreatIdRoute: typeof AppThreatsThreatIdRoute
  AppThreatsIndexRoute: typeof AppThreatsIndexRoute
}

const AppThreatsRouteChildren: AppThreatsRouteChildren = {
  AppThreatsThreatIdRoute: AppThreatsThreatIdRoute,
  AppThreatsIndexRoute: AppThreatsIndexRoute,
}

const AppThreatsRouteWithChildren = AppThreatsRoute._addFileChildren(
  AppThreatsRouteChildren,
)

interface AppRouteChildren {
  AppDetectionsRoute: typeof AppDetectionsRoute
  AppReportsRoute: typeof AppReportsRoute
  AppSettingsRoute: typeof AppSettingsRouteWithChildren
  AppThreatsRoute: typeof AppThreatsRouteWithChildren
  AppIndexRoute: typeof AppIndexRoute
}

const AppRouteChildren: AppRouteChildren = {
  AppDetectionsRoute: AppDetectionsRoute,
  AppReportsRoute: AppReportsRoute,
  AppSettingsRoute: AppSettingsRouteWithChildren,
  AppThreatsRoute: AppThreatsRouteWithChildren,
  AppIndexRoute: AppIndexRoute,
}

const AppRouteWithChildren = AppRoute._addFileChildren(AppRouteChildren)

interface AuthRouteChildren {
  AuthCreateAccountRoute: typeof AuthCreateAccountRoute
  AuthResetPasswordRoute: typeof AuthResetPasswordRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthCreateAccountRoute: AuthCreateAccountRoute,
  AuthResetPasswordRoute: AuthResetPasswordRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof AuthRouteWithChildren
  '/detections': typeof AppDetectionsRoute
  '/reports': typeof AppReportsRoute
  '/settings': typeof AppSettingsRouteWithChildren
  '/threats': typeof AppThreatsRouteWithChildren
  '/create-account': typeof AuthCreateAccountRoute
  '/reset-password': typeof AuthResetPasswordRoute
  '/': typeof AppIndexRoute
  '/settings/enrichments': typeof AppSettingsEnrichmentsRouteWithChildren
  '/settings/platform': typeof AppSettingsPlatformRouteWithChildren
  '/settings/users-and-access': typeof AppSettingsUsersAndAccessRouteWithChildren
  '/threats/$threatId': typeof AppThreatsThreatIdRoute
  '/settings/': typeof AppSettingsIndexRoute
  '/threats/': typeof AppThreatsIndexRoute
  '/settings/enrichments/custom': typeof AppSettingsEnrichmentsCustomRoute
  '/settings/enrichments/platform': typeof AppSettingsEnrichmentsPlatformRoute
  '/settings/platform/pipelines': typeof AppSettingsPlatformPipelinesRoute
  '/settings/platform/tenants': typeof AppSettingsPlatformTenantsRoute
  '/settings/users-and-access/roles': typeof AppSettingsUsersAndAccessRolesRoute
  '/settings/users-and-access/users': typeof AppSettingsUsersAndAccessUsersRoute
}

export interface FileRoutesByTo {
  '': typeof AuthRouteWithChildren
  '/detections': typeof AppDetectionsRoute
  '/reports': typeof AppReportsRoute
  '/create-account': typeof AuthCreateAccountRoute
  '/reset-password': typeof AuthResetPasswordRoute
  '/': typeof AppIndexRoute
  '/settings/enrichments': typeof AppSettingsEnrichmentsRouteWithChildren
  '/settings/platform': typeof AppSettingsPlatformRouteWithChildren
  '/settings/users-and-access': typeof AppSettingsUsersAndAccessRouteWithChildren
  '/threats/$threatId': typeof AppThreatsThreatIdRoute
  '/settings': typeof AppSettingsIndexRoute
  '/threats': typeof AppThreatsIndexRoute
  '/settings/enrichments/custom': typeof AppSettingsEnrichmentsCustomRoute
  '/settings/enrichments/platform': typeof AppSettingsEnrichmentsPlatformRoute
  '/settings/platform/pipelines': typeof AppSettingsPlatformPipelinesRoute
  '/settings/platform/tenants': typeof AppSettingsPlatformTenantsRoute
  '/settings/users-and-access/roles': typeof AppSettingsUsersAndAccessRolesRoute
  '/settings/users-and-access/users': typeof AppSettingsUsersAndAccessUsersRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_app': typeof AppRouteWithChildren
  '/_auth': typeof AuthRouteWithChildren
  '/_app/detections': typeof AppDetectionsRoute
  '/_app/reports': typeof AppReportsRoute
  '/_app/settings': typeof AppSettingsRouteWithChildren
  '/_app/threats': typeof AppThreatsRouteWithChildren
  '/_auth/create-account': typeof AuthCreateAccountRoute
  '/_auth/reset-password': typeof AuthResetPasswordRoute
  '/_app/': typeof AppIndexRoute
  '/_app/settings/enrichments': typeof AppSettingsEnrichmentsRouteWithChildren
  '/_app/settings/platform': typeof AppSettingsPlatformRouteWithChildren
  '/_app/settings/users-and-access': typeof AppSettingsUsersAndAccessRouteWithChildren
  '/_app/threats/$threatId': typeof AppThreatsThreatIdRoute
  '/_app/settings/': typeof AppSettingsIndexRoute
  '/_app/threats/': typeof AppThreatsIndexRoute
  '/_app/settings/enrichments/custom': typeof AppSettingsEnrichmentsCustomRoute
  '/_app/settings/enrichments/platform': typeof AppSettingsEnrichmentsPlatformRoute
  '/_app/settings/platform/pipelines': typeof AppSettingsPlatformPipelinesRoute
  '/_app/settings/platform/tenants': typeof AppSettingsPlatformTenantsRoute
  '/_app/settings/users-and-access/roles': typeof AppSettingsUsersAndAccessRolesRoute
  '/_app/settings/users-and-access/users': typeof AppSettingsUsersAndAccessUsersRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/detections'
    | '/reports'
    | '/settings'
    | '/threats'
    | '/create-account'
    | '/reset-password'
    | '/'
    | '/settings/enrichments'
    | '/settings/platform'
    | '/settings/users-and-access'
    | '/threats/$threatId'
    | '/settings/'
    | '/threats/'
    | '/settings/enrichments/custom'
    | '/settings/enrichments/platform'
    | '/settings/platform/pipelines'
    | '/settings/platform/tenants'
    | '/settings/users-and-access/roles'
    | '/settings/users-and-access/users'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/detections'
    | '/reports'
    | '/create-account'
    | '/reset-password'
    | '/'
    | '/settings/enrichments'
    | '/settings/platform'
    | '/settings/users-and-access'
    | '/threats/$threatId'
    | '/settings'
    | '/threats'
    | '/settings/enrichments/custom'
    | '/settings/enrichments/platform'
    | '/settings/platform/pipelines'
    | '/settings/platform/tenants'
    | '/settings/users-and-access/roles'
    | '/settings/users-and-access/users'
  id:
    | '__root__'
    | '/_app'
    | '/_auth'
    | '/_app/detections'
    | '/_app/reports'
    | '/_app/settings'
    | '/_app/threats'
    | '/_auth/create-account'
    | '/_auth/reset-password'
    | '/_app/'
    | '/_app/settings/enrichments'
    | '/_app/settings/platform'
    | '/_app/settings/users-and-access'
    | '/_app/threats/$threatId'
    | '/_app/settings/'
    | '/_app/threats/'
    | '/_app/settings/enrichments/custom'
    | '/_app/settings/enrichments/platform'
    | '/_app/settings/platform/pipelines'
    | '/_app/settings/platform/tenants'
    | '/_app/settings/users-and-access/roles'
    | '/_app/settings/users-and-access/users'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AppRoute: typeof AppRouteWithChildren
  AuthRoute: typeof AuthRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  AppRoute: AppRouteWithChildren,
  AuthRoute: AuthRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_app",
        "/_auth"
      ]
    },
    "/_app": {
      "filePath": "_app.tsx",
      "children": [
        "/_app/detections",
        "/_app/reports",
        "/_app/settings",
        "/_app/threats",
        "/_app/"
      ]
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/create-account",
        "/_auth/reset-password"
      ]
    },
    "/_app/detections": {
      "filePath": "_app/detections.tsx",
      "parent": "/_app"
    },
    "/_app/reports": {
      "filePath": "_app/reports.tsx",
      "parent": "/_app"
    },
    "/_app/settings": {
      "filePath": "_app/settings.tsx",
      "parent": "/_app",
      "children": [
        "/_app/settings/enrichments",
        "/_app/settings/platform",
        "/_app/settings/users-and-access",
        "/_app/settings/"
      ]
    },
    "/_app/threats": {
      "filePath": "_app/threats.tsx",
      "parent": "/_app",
      "children": [
        "/_app/threats/$threatId",
        "/_app/threats/"
      ]
    },
    "/_auth/create-account": {
      "filePath": "_auth/create-account.tsx",
      "parent": "/_auth"
    },
    "/_auth/reset-password": {
      "filePath": "_auth/reset-password.tsx",
      "parent": "/_auth"
    },
    "/_app/": {
      "filePath": "_app/index.tsx",
      "parent": "/_app"
    },
    "/_app/settings/enrichments": {
      "filePath": "_app/settings/enrichments.tsx",
      "parent": "/_app/settings",
      "children": [
        "/_app/settings/enrichments/custom",
        "/_app/settings/enrichments/platform"
      ]
    },
    "/_app/settings/platform": {
      "filePath": "_app/settings/platform.tsx",
      "parent": "/_app/settings",
      "children": [
        "/_app/settings/platform/pipelines",
        "/_app/settings/platform/tenants"
      ]
    },
    "/_app/settings/users-and-access": {
      "filePath": "_app/settings/users-and-access.tsx",
      "parent": "/_app/settings",
      "children": [
        "/_app/settings/users-and-access/roles",
        "/_app/settings/users-and-access/users"
      ]
    },
    "/_app/threats/$threatId": {
      "filePath": "_app/threats/$threatId.tsx",
      "parent": "/_app/threats"
    },
    "/_app/settings/": {
      "filePath": "_app/settings/index.tsx",
      "parent": "/_app/settings"
    },
    "/_app/threats/": {
      "filePath": "_app/threats/index.tsx",
      "parent": "/_app/threats"
    },
    "/_app/settings/enrichments/custom": {
      "filePath": "_app/settings/enrichments/custom.tsx",
      "parent": "/_app/settings/enrichments"
    },
    "/_app/settings/enrichments/platform": {
      "filePath": "_app/settings/enrichments/platform.tsx",
      "parent": "/_app/settings/enrichments"
    },
    "/_app/settings/platform/pipelines": {
      "filePath": "_app/settings/platform/pipelines.tsx",
      "parent": "/_app/settings/platform"
    },
    "/_app/settings/platform/tenants": {
      "filePath": "_app/settings/platform/tenants.tsx",
      "parent": "/_app/settings/platform"
    },
    "/_app/settings/users-and-access/roles": {
      "filePath": "_app/settings/users-and-access/roles.tsx",
      "parent": "/_app/settings/users-and-access"
    },
    "/_app/settings/users-and-access/users": {
      "filePath": "_app/settings/users-and-access/users.tsx",
      "parent": "/_app/settings/users-and-access"
    }
  }
}
ROUTE_MANIFEST_END */
