import { useCallback, useEffect, useState } from 'react';
import { getRouteApi } from '@tanstack/react-router';
import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons';

import { useStore } from 'services/store';
import { useTranslate } from 'services/i18n/useTranslate';
import { cn } from 'utils/styles';
import { isValidTimeRange } from 'utils/dateTimeValidation';
import { resetRelativeQueriesPredicate } from 'utils/detections';
import { BASE_RANGE_OPTIONS, type DateTimeFilter } from 'consts/dateTime';

import { IconButton } from 'components/common/IconButton';
import { DashboardLayout } from 'components/common/DashboardLayout';
import { PresetDateTimePicker } from 'components/common/PresetDateTimePicker';
import { Tabs, TabContent, TabTrigger, TabsList } from 'components/common/Tabs';

import { ThreatsList } from './ThreatsList';
import { Facets } from './Facets';
import { ThreatsProvider } from './ThreatsContext';
import { FilterBar } from './FilterBar';
import { Histogram } from './Histogram';
import { SavedFilters } from './SavedFilters';

const Route = getRouteApi('/_app/threats/');

type TabType = 'filters' | 'savedViews';

const THREATS_LIVE_UPDATE_REFRESH_INTERVAL = 5 * 60 * 1000;

const ThreatsSceneContent = () => {
  const t = useTranslate();
  const navigate = Route.useNavigate();
  const routeSearch = Route.useSearch();
  const queryClient = useQueryClient();
  const [isLiveUpdateOn, setIsLiveUpdateOn] = useState(false);
  const [activeTab, setActiveTab] = useState<TabType>('filters');
  const { areThreatsFacetsOpen, toggleThreatsFacets } = useStore((state) => state.dashboardConfigurationSlice);

  const toggleLiveUpdate = useCallback(() => {
    setIsLiveUpdateOn((prev) => !prev);
  }, []);

  const changeTimerange = useCallback(
    (data: DateTimeFilter) => {
      setIsLiveUpdateOn(false);

      void navigate({
        search: (previousParams) => {
          const withoutTimerange = _.omit(previousParams, ['startTime', 'endTime', 'timeRange']);

          return {
            ...withoutTimerange,
            ...data,
          };
        },
      });
    },
    [navigate],
  );

  const timeRange = isValidTimeRange(routeSearch.timeRange) ? routeSearch.timeRange : undefined;

  useEffect(() => {
    void queryClient.removeQueries({
      predicate: (query) => {
        return resetRelativeQueriesPredicate('threats', timeRange, query.queryKey);
      },
    });
  }, [queryClient, timeRange]);

  const handleRefreshData = () => {
    void queryClient.resetQueries({ queryKey: ['threats'], exact: false });
  };

  return (
    <ThreatsProvider>
      <DashboardLayout
        isCollapsed={!areThreatsFacetsOpen}
        leftSection={
          <>
            <Tabs
              type="enclosed"
              value={activeTab}
              isTransparent
              onValueChange={(value) => setActiveTab(value as TabType)}
            >
              <TabsList className="flex max-w-max items-start bg-neutral-3">
                <TabTrigger className={cn('py-1', !areThreatsFacetsOpen && 'hidden')} value="filters">
                  {t('threats.tabs.filters')}
                </TabTrigger>
                <TabTrigger className={cn('py-1', !areThreatsFacetsOpen && 'hidden')} value="savedViews">
                  {t('threats.tabs.saved_views')}
                </TabTrigger>

                <div className="inline-flex !border-l !border-l-neutral-6">
                  <IconButton variant="soft" color="gray" className="!rounded-none" onClick={toggleThreatsFacets}>
                    {areThreatsFacetsOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                  </IconButton>
                </div>
              </TabsList>
              <div className="border-r border-neutral-5">
                <TabContent
                  value="filters"
                  className={cn(
                    'visible bg-neutral-3 opacity-100 transition-all',
                    !areThreatsFacetsOpen && 'invisible opacity-0',
                  )}
                >
                  <PresetDateTimePicker
                    rangeOptions={BASE_RANGE_OPTIONS}
                    startTime={routeSearch.startTime}
                    endTime={routeSearch.endTime}
                    timeRange={timeRange}
                    onChange={changeTimerange}
                    isLiveUpdateOn={isLiveUpdateOn}
                    onToggleLiveUpdate={toggleLiveUpdate}
                    onRefreshData={handleRefreshData}
                    refreshInterval={THREATS_LIVE_UPDATE_REFRESH_INTERVAL}
                  />

                  <Facets />
                </TabContent>
                <TabContent
                  value="savedViews"
                  className={cn('opacity-100 transition-all', !areThreatsFacetsOpen && 'opacity-0')}
                >
                  <SavedFilters />
                </TabContent>
              </div>
            </Tabs>
          </>
        }
        rightSection={
          <>
            <FilterBar />
            <Histogram />
            <ThreatsList onRefreshData={handleRefreshData} />
          </>
        }
        rightSectionDataTestId="threats-content-container"
      />
    </ThreatsProvider>
  );
};

export const ThreatsScene = () => {
  return (
    <ThreatsProvider>
      <ThreatsSceneContent />
    </ThreatsProvider>
  );
};
