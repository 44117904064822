import { z } from 'zod';
import { Outlet, createFileRoute } from '@tanstack/react-router';

import { createDateTimeValidationSchema } from 'utils/dateTimeValidation';

const filterSchema = z.object({
  riskScore: z.array(z.enum(['low', 'medium', 'high'])).optional(),
  tenantId: z.array(z.string()).optional(),
  pipelineId: z.array(z.string()).optional(),
  entity: z.array(z.string()).optional(),
  dimension: z.array(z.string()).optional(),
  status: z.array(z.enum(['active', 'inactive'])).optional(),
  geo: z.array(z.string()).optional(),
  mitreId: z.array(z.string()).optional(),
  workflowAssignedTo: z.array(z.string()).optional(),
  workflowStatus: z.array(z.enum(['open', 'under_review', 'acknowledged', 'closed'])).optional(),
});

const threatsSchema = z.intersection(filterSchema, createDateTimeValidationSchema());

export const Route = createFileRoute('/_app/threats')({
  component: Outlet,
  validateSearch: threatsSchema,
  head: () => ({
    meta: [
      {
        name: 'pageTitle',
        content: 'threats.page_title',
      },
      {
        name: 'metaTitle',
        content: 'threats.page_title',
      },
      {
        name: 'pageDescription',
        content: 'threats.page_description',
      },
    ],
  }),
});
