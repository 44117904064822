import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { Platform } from 'components/scenes/Settings/Enrichments/Platform';

export const Route = createFileRoute('/_app/settings/enrichments/platform')({
  component: Platform,
  validateSearch: z.object({}).or(
    z.object({
      enrichmentSidebarIntent: z.literal('edit'),
      enrichmentType: z.string(),
      tab: z.enum(['assign-pipelines', 'edit-enrichment']),
    }),
  ),
});
