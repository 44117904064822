import { type ReactNode } from 'react';

import { Heading } from 'components/common/Heading';

type WidgetProps = {
  title: string;
  headerLeft?: ReactNode;
  headerRight?: ReactNode;
  className?: string;
  children: ReactNode;
};

export const WidgetContainer = ({ title, headerLeft, headerRight, className, children }: WidgetProps) => {
  return (
    <div className={`border border-neutral-a5 bg-neutral-2 ${className}`}>
      <section className="flex min-h-10 items-center justify-between gap-2 border-b border-b-neutral-a5 p-2 text-neutral-11">
        <span className="flex items-center gap-2">
          {headerLeft} <Heading size="2">{title}</Heading>
        </span>
        {headerRight && <div className="mr-2 flex items-center">{headerRight}</div>}
      </section>

      <div className="p-3">{children}</div>
    </div>
  );
};
