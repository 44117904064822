import { createFileRoute } from '@tanstack/react-router';
import { ReportsScene } from 'components/scenes/Reports';

export const Route = createFileRoute('/_app/reports')({
  component: ReportsScene,
  head: () => ({
    meta: [
      {
        name: 'pageTitle',
        content: 'reports.page_title',
      },
      {
        name: 'metaTitle',
        content: 'reports.page_title',
      },
      {
        name: 'pageDescription',
        content: 'reports.page_description',
      },
    ],
  }),
});
