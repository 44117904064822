import { Badge } from 'components/common/Badge';
import { AdminUserIcon, EyeOpenIcon } from 'components/common/Icons';
import { useTranslate } from 'services/i18n/useTranslate';
import type { components } from 'types/schemas/api-schema';
import type { FlattenedTranslationKeys } from 'types/translations';

type PermissionType = 'read' | 'write' | 'delete';
type RoleScopes = components['schemas']['role']['scopes'];

export function ReadScopeBadge({ scopeName }: { scopeName: string }) {
  return (
    <Badge size="1" variant="soft" color="teal">
      <EyeOpenIcon /> {scopeName}
    </Badge>
  );
}

export function WriteScopeBadge({ scopeName }: { scopeName: string }) {
  return (
    <Badge size="1" variant="soft" color="violet">
      <AdminUserIcon /> {scopeName}
    </Badge>
  );
}

export function DeleteScopeBadge({ scopeName }: { scopeName: string }) {
  return (
    <Badge size="1" variant="soft" color="red">
      <AdminUserIcon /> {scopeName}
    </Badge>
  );
}

type ScopeBadgeProps = {
  scopeName: string;
  permission: PermissionType;
};

export function ScopeBadge({ scopeName, permission }: ScopeBadgeProps) {
  if (permission === 'read') {
    return <ReadScopeBadge scopeName={scopeName} />;
  }

  if (permission === 'write') {
    return <WriteScopeBadge scopeName={scopeName} />;
  }

  return <DeleteScopeBadge scopeName={scopeName} />;
}

export const ScopeBadges = ({ scopes }: { scopes: RoleScopes }) => {
  const t = useTranslate();
  const allScopes = [...scopes.global, ...scopes.tenant.flatMap((tenant) => tenant.scopes)];

  return (
    <div className="flex flex-wrap gap-2">
      {allScopes.map((scope) => {
        const [scopeName, permission] = scope.split(':') as [string, PermissionType];
        const tkey = `common.scopes.${scopeName}` as FlattenedTranslationKeys;

        return <ScopeBadge key={scope} scopeName={t(tkey)} permission={permission} />;
      })}
    </div>
  );
};
