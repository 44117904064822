import { useCallback, useMemo } from 'react';
import { format } from 'date-fns';
import { useNavigate } from '@tanstack/react-router';

import type { components } from 'types/schemas/api-schema';
import { handleKeyDown } from 'utils/handleKeyDown';
import { getRiskScoreRange } from 'utils/detections';
import { extractEntitiesFromThreat } from 'utils/detections';
import { dateTimeFormatWithoutSeconds } from 'consts/dateFormats';
import { useTenants } from 'services/api/tenants';
import { usePipelines } from 'services/api/pipelines';
import { useGetCallerDisplayName } from 'services/api/callers';

import { Text } from 'components/common/Text';
import { Badge } from 'components/common/Badge';
import { Skeleton } from 'components/common/Skeleton';

import { useThreatsContext } from '../ThreatsContext';
import {
  ThreatAssigneeBadge,
  ThreatEntityBadge,
  ThreatPipelineBadge,
  ThreatRiskScoreBadge,
  ThreatStatusBadge,
  ThreatTenantBadge,
  ThreatWorkflowStatusBadge,
} from '../common/ThreatsBadges';

type RecordCardProps = {
  isLoading?: boolean;
  threat: components['schemas']['threat'];
};

const MAX_ENTITIES = 2;

export const ThreatCard = ({ isLoading, threat }: RecordCardProps) => {
  const navigate = useNavigate({ from: '/threats' });
  const { toggleFilter, getIsFilterActive } = useThreatsContext();

  const navigateToThreat = useCallback(
    (event?: React.MouseEvent<HTMLSpanElement | HTMLButtonElement> | React.KeyboardEvent<HTMLSpanElement>) => {
      event?.stopPropagation();
      void navigate({ to: '/threats/$threatId', params: { threatId: threat.id } });
    },
    [navigate, threat.id],
  );

  const tenantsQueryData = useTenants();

  const getTenantName = useCallback(
    (tenantId: string) => {
      return (tenantsQueryData.data?.data || [])?.find((tenant) => tenant.id === tenantId)?.name ?? tenantId;
    },
    [tenantsQueryData.data?.data],
  );

  const rsRange = getRiskScoreRange(threat.riskScore.score);

  const entities = useMemo(() => {
    return extractEntitiesFromThreat(threat);
  }, [threat]);

  const firstEvidenceCreatedAt = threat.evidence[0]!.createdAt;
  const lastEvidenceCreatedAt = threat.evidence[threat.evidence.length - 1]?.createdAt;

  const shouldDisplayLastEvidence = lastEvidenceCreatedAt && firstEvidenceCreatedAt !== lastEvidenceCreatedAt;

  const pipelinesQueryData = usePipelines();
  const getPipelineName = useCallback(
    (pipelineId: string) => {
      return (pipelinesQueryData.data?.data || [])?.find((pipeline) => pipeline.id === pipelineId)?.name ?? pipelineId;
    },
    [pipelinesQueryData.data?.data],
  );
  const isPipelineLoading = pipelinesQueryData.isLoading;
  const isThreatLoading = isLoading || tenantsQueryData.isLoading || isPipelineLoading;

  const { getCallerDisplayName } = useGetCallerDisplayName();

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={navigateToThreat}
      onKeyDown={handleKeyDown(navigateToThreat)}
      className="flex w-full flex-col gap-3 border-b border-neutral-3 bg-neutral-3 p-4 hover:ring-2 hover:ring-inset hover:ring-neutral-5 focus:outline-accent-9"
    >
      <div className="flex items-start justify-between gap-4">
        <div className="flex flex-wrap items-center gap-x-4 gap-y-1">
          <Skeleton isLoading={isThreatLoading}>
            <div>
              <ThreatRiskScoreBadge
                className="mt-0.5"
                riskScore={threat.riskScore.score}
                isActive={getIsFilterActive('riskScore', rsRange)}
                onClick={() => toggleFilter('riskScore', rsRange)}
              />
            </div>
          </Skeleton>

          <Skeleton isLoading={isThreatLoading}>
            <Text size="3" weight="medium">
              {threat.name}
            </Text>
          </Skeleton>
        </div>

        <Skeleton isLoading={isThreatLoading}>
          <ThreatStatusBadge
            className="mt-0.5"
            status={threat.status as 'active' | 'inactive'}
            isActive={getIsFilterActive('status', threat.status)}
            onClick={() => toggleFilter('status', threat.status)}
          />
        </Skeleton>
      </div>

      <div className="flex flex-wrap gap-2">
        {entities.slice(0, MAX_ENTITIES).map((entity, index) => (
          <Skeleton key={index} isLoading={isThreatLoading}>
            <ThreatEntityBadge
              entity={entity}
              isActive={getIsFilterActive('entity', entity.value)}
              onClick={() => toggleFilter('entity', entity.value)}
            />
          </Skeleton>
        ))}
        {entities.length > MAX_ENTITIES && (
          <Skeleton isLoading={isThreatLoading}>
            <Badge size="1" variant="soft" color="sky">
              +{entities.length - MAX_ENTITIES}
            </Badge>
          </Skeleton>
        )}
      </div>

      <Skeleton isLoading={isThreatLoading}>
        <div className="flex-1 text-xs text-neutral-11">
          {shouldDisplayLastEvidence ? (
            <>
              {format(new Date(firstEvidenceCreatedAt), dateTimeFormatWithoutSeconds)} -{' '}
              {format(new Date(lastEvidenceCreatedAt), dateTimeFormatWithoutSeconds)}
            </>
          ) : (
            format(new Date(firstEvidenceCreatedAt), dateTimeFormatWithoutSeconds)
          )}
        </div>
      </Skeleton>

      <div className="flex flex-wrap items-center justify-between gap-3">
        <div className="flex flex-wrap items-center gap-3 overflow-hidden">
          <Skeleton isLoading={isThreatLoading}>
            <ThreatTenantBadge
              isActive={getIsFilterActive('tenantId', threat.tenantId)}
              onClick={() => toggleFilter('tenantId', threat.tenantId)}
            >
              {getTenantName(threat.tenantId)}
            </ThreatTenantBadge>
          </Skeleton>

          <Skeleton isLoading={isThreatLoading}>
            <ThreatPipelineBadge
              isActive={getIsFilterActive('pipelineId', threat.pipelineIds[0]!)}
              onClick={() => toggleFilter('pipelineId', threat.pipelineIds[0]!)}
            >
              {getPipelineName(threat.pipelineIds[0]!)}
            </ThreatPipelineBadge>
          </Skeleton>
        </div>

        <div className="flex flex-1 items-center justify-end gap-3">
          <Skeleton isLoading={isThreatLoading}>
            <ThreatWorkflowStatusBadge
              isActive={getIsFilterActive('workflowStatus', threat.workflow?.status || 'open')}
              onClick={() => toggleFilter('workflowStatus', threat.workflow?.status || 'open')}
              workflowStatus={threat?.workflow?.status || 'open'}
            />
          </Skeleton>
          <Skeleton isLoading={isThreatLoading}>
            <ThreatAssigneeBadge
              isActive={getIsFilterActive('workflowAssignedTo', threat?.workflow?.assignedTo || 'null')}
              onClick={() => toggleFilter('workflowAssignedTo', threat?.workflow?.assignedTo || 'null')}
            >
              {getCallerDisplayName(threat.workflow?.assignedTo)}
            </ThreatAssigneeBadge>
          </Skeleton>
        </div>
      </div>
    </div>
  );
};
