import { useMemo, useState, type ReactNode } from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  createColumnHelper,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import Markdown from 'react-markdown';
import { ExternalLinkIcon } from '@radix-ui/react-icons';

import { MITRE_SUBTECHNIQUES, MITRE_TACTICS, MITRE_TECHNIQUES } from 'consts/mitre';
import { LOADING_THREATS } from 'mocks/threat';
import { fullDateTimeFormat } from 'consts/dateFormats';
import { useTranslate } from 'services/i18n/useTranslate';
import type { components } from 'types/schemas/api-schema';

import { Text } from 'components/common/Text';
import { Link } from 'components/common/Link';
import { Badge } from 'components/common/Badge';
import { Dialog } from 'components/common/Dialog';
import { Button } from 'components/common/Button';
import { ChatIcon } from 'components/common/Icons';
import { Skeleton } from 'components/common/Skeleton';
import { Translate } from 'components/common/Translate';
import { Collapsible } from 'components/common/Collapsible';
import { TabContent, Tabs, TabsList, TabTrigger } from 'components/common/Tabs';
import { Table, SortableColumnHeaderCell } from 'components/common/Table';
import { ThreatEntityBadge } from 'components/scenes/Threats/common/ThreatsBadges';

type TabType = (typeof TABS)[number];
type Evidence = components['schemas']['threatEvidence'];
type Perception = components['schemas']['threatPerception'];

const TABS = ['findings', 'investigation-details'] as const;
const DEFAULT_DATA: Perception[] = [];
const PerceptionsColumnHelper = createColumnHelper<Perception>();

const PerceptionTable = ({ isLoading, perceptions = [] }: { isLoading: boolean; perceptions?: Perception[] }) => {
  const t = useTranslate();

  const columns = useMemo(() => {
    return [
      PerceptionsColumnHelper.accessor('createdAt', {
        header: () => <Text>{t('threats.evidence.tabs.findings.table.headers.timestamp')}</Text>,
        cell: (info) => (
          <Skeleton isLoading={isLoading}>
            <Text>{format(info.getValue(), fullDateTimeFormat)}</Text>
          </Skeleton>
        ),
      }),
      PerceptionsColumnHelper.accessor((row) => row.entities?.[0], {
        id: 'source_entity',
        enableSorting: false,
        header: () => <Text>{t('threats.evidence.tabs.findings.table.headers.source_entity')}</Text>,
        cell: (info) => {
          const sourceEntity = info.getValue();
          return sourceEntity ? (
            <Skeleton isLoading={isLoading}>
              <ThreatEntityBadge entity={sourceEntity} />
            </Skeleton>
          ) : null;
        },
      }),
      PerceptionsColumnHelper.accessor((row) => row.entities?.[1], {
        id: 'destination_entity',
        enableSorting: false,
        header: () => <Text>{t('threats.evidence.tabs.findings.table.headers.destination_entity')}</Text>,
        cell: (info) => {
          const destinationEntity = info.getValue();
          return destinationEntity ? (
            <Skeleton isLoading={isLoading}>
              <ThreatEntityBadge entity={destinationEntity} />
            </Skeleton>
          ) : null;
        },
      }),
    ];
  }, [t, isLoading]);

  const table = useReactTable({
    data: perceptions ?? DEFAULT_DATA,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Table.Root variant="surface" className="!rounded-2" size="1">
      <Table.Header className="bg-neutral-a2">
        {table.getHeaderGroups().map((headerGroup) => (
          <Table.Row key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <SortableColumnHeaderCell key={header.id} header={header} />
            ))}
          </Table.Row>
        ))}
      </Table.Header>

      <Table.Body>
        {table.getRowModel().rows.map((row) => {
          return (
            <Table.Row key={row.id} align="center">
              {row.getVisibleCells().map((cell) => {
                return (
                  <Table.Cell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Table.Cell>
                );
              })}
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table.Root>
  );
};

function RenderMarkdown({ source }: { source: string }) {
  return (
    <Markdown
      components={{
        a: ({ href, children }) => <Link externalTo={href}>{children}</Link>,
      }}
    >
      {source}
    </Markdown>
  );
}

const Findings = ({ isLoading, evidence }: { isLoading: boolean; evidence: Evidence }) => {
  const t = useTranslate();

  const externalMitreRefs = evidence.externalRefs
    ?.filter((ref) => ref?.type === 'MITRE')
    .map((ref) => ref?.value)
    .concat(
      evidence?.observations
        .map((o) => o.externalRefs?.filter((ref) => ref?.type === 'MITRE').map((v) => v?.value))
        .flat(),
    );

  return (
    <div className="bg-neutral-3">
      <Collapsible
        key={evidence.id}
        chevronPosition="left"
        variant="transparent"
        noBorder
        defaultOpen
        title={
          <Skeleton isLoading={isLoading}>
            <Badge size="1" variant="soft" color="sky" className="mr-1">
              {evidence.name}
            </Badge>
          </Skeleton>
        }
      >
        <div className="space-y-2 px-2 pb-4">
          <div className="max-w-3xl bg-neutral-4 p-2">
            <Text size="2" className="text-neutral-11">
              <Skeleton isLoading={isLoading}>{evidence.description}</Skeleton>
            </Text>
          </div>

          <PerceptionTable isLoading={isLoading} perceptions={evidence.observations[0]?.perceptions} />
        </div>

        {!isLoading && externalMitreRefs?.length && (
          <div className="px-2 pb-2">
            <Text as="div" size="2" weight="medium" mb="2">
              {t('common.mitre_attack')}
            </Text>

            <div className="flex flex-wrap gap-2">
              {externalMitreRefs.map((mitreRef) => {
                if (!mitreRef) {
                  return null;
                }

                let name = '';
                let url = '';
                let content: ReactNode[] = [];
                const mitreTactic = MITRE_TACTICS.find((ta) => ta.tacticId === mitreRef);
                const mitreTechnique = MITRE_TECHNIQUES.find((te) => te.techniqueId === mitreRef);
                const mitreSubtechnique = MITRE_SUBTECHNIQUES.find((st) => st.subtechniqueId === mitreRef);

                if (mitreSubtechnique) {
                  name = mitreSubtechnique.name;
                  url = mitreSubtechnique.subtechniqueUrl;

                  content = [
                    !!mitreSubtechnique.xMitrePlatforms?.length && (
                      <Translate
                        tkey="threats.evidence.tabs.findings.platforms_key_value"
                        components={{
                          bold: <strong />,
                        }}
                        values={{
                          platforms: mitreSubtechnique.xMitrePlatforms.join(', '),
                        }}
                      />
                    ),
                    <>
                      <Text as="p" weight="bold">
                        {t('common.description')}
                      </Text>

                      <RenderMarkdown source={mitreSubtechnique.description} />
                    </>,
                    <>
                      <Text as="p" weight="bold">
                        {t('threats.evidence.tabs.findings.detection')}
                      </Text>

                      {mitreSubtechnique.xMitreDetection}
                    </>,
                  ];
                } else if (mitreTechnique) {
                  name = mitreTechnique.name;
                  url = mitreTechnique.techniqueUrl;
                  const mitreTactic = MITRE_TACTICS.find((ta) => ta.tacticId === mitreTechnique.tacticId)!;
                  content = [
                    <Translate
                      tkey="threats.evidence.tabs.findings.mitre_tactic_key_value"
                      components={{
                        bold: <strong />,
                      }}
                      values={{
                        tactic: mitreTactic.name,
                      }}
                    />,
                    !!mitreTechnique.xMitrePlatforms?.length && (
                      <Translate
                        tkey="threats.evidence.tabs.findings.platforms_key_value"
                        components={{
                          bold: <strong />,
                        }}
                        values={{
                          platforms: mitreTechnique.xMitrePlatforms.join(', '),
                        }}
                      />
                    ),
                    <>
                      <Text as="p" weight="bold">
                        {t('common.description')}
                      </Text>

                      <RenderMarkdown source={mitreTechnique.description} />
                    </>,
                    <>
                      <Text as="p" weight="bold">
                        {t('threats.evidence.tabs.findings.detection')}
                      </Text>

                      {mitreTechnique.xMitreDetection}
                    </>,
                  ];
                } else if (mitreTactic) {
                  name = mitreTactic.name;
                  url = mitreTactic.tacticUrl;
                  content = [
                    <>
                      <Text as="p" weight="bold">
                        {t('common.description')}
                      </Text>

                      <RenderMarkdown source={mitreTactic.description} />
                    </>,
                  ];
                }

                return (
                  <Dialog.Root key={mitreRef}>
                    <Dialog.Trigger>
                      <Button size="1" variant="soft" color="gray">
                        <ChatIcon />
                        {name} ({mitreRef})
                      </Button>
                    </Dialog.Trigger>

                    <Dialog.Content aria-describedby={undefined}>
                      <Dialog.Title>
                        {mitreRef}: {name}
                      </Dialog.Title>
                      <Dialog.Description>
                        <Link externalTo={url} className="flex items-center gap-2">
                          <ExternalLinkIcon /> External link
                        </Link>
                      </Dialog.Description>

                      <div className="mt-3 space-y-1">
                        {content.filter(Boolean).map((c, i) => (
                          <div key={i}>{c}</div>
                        ))}
                      </div>

                      <div className="flex justify-end">
                        <Dialog.Close>
                          <Button variant="soft" color="gray">
                            {t('common.close')}
                          </Button>
                        </Dialog.Close>
                      </div>
                    </Dialog.Content>
                  </Dialog.Root>
                );
              })}
            </div>
          </div>
        )}
      </Collapsible>
    </div>
  );
};

export const ThreatEvidence = ({ isLoading, evidence }: { isLoading: boolean; evidence?: Evidence[] }) => {
  const t = useTranslate();
  const [activeTab, setActiveTab] = useState<TabType>(TABS[0]);

  const evidenceData = evidence ?? LOADING_THREATS[0]?.evidence ?? [];

  const handleTabChange = (value: string) => {
    setActiveTab(value as TabType);
  };

  return (
    <div className="mt-3 border-b border-neutral-a5 pb-3">
      <Tabs type="enclosed" size="1" value={activeTab} onValueChange={handleTabChange}>
        <TabsList className="border-b border-neutral-a5">
          <TabTrigger value={TABS[0]}>{t('threats.evidence.tabs.findings.label')}</TabTrigger>
        </TabsList>
        <div className="bg-neutral-2 p-4">
          <TabContent value={TABS[0]}>
            {evidenceData.length > 0 &&
              evidenceData.map((e) => <Findings isLoading={isLoading} key={e.id} evidence={e} />)}
          </TabContent>
        </div>
      </Tabs>
    </div>
  );
};
