export const formatByteSize = (bytes: number) => {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const kilo = 1024;
  const decimals = 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const power = Math.max(Math.floor(Math.log(bytes) / Math.log(kilo)), 0);

  const size = parseFloat((bytes / Math.pow(kilo, power)).toFixed(decimals));

  return `${size} ${sizes[power]}`;
};

/**
 * Implementation of toFixed() that treats floats more like decimals
 */
export const toFixed = (value: number, precision: number) => {
  const exponentialForm = parseFloat(value + 'e' + precision);
  const rounded = Math.round(exponentialForm);
  const finalResult = Number(rounded + 'e-' + precision).toFixed(precision);
  return finalResult;
};

export const formatNumber = (
  value: number,
  { precision = 2, omitTrailingZeroes = true }: { precision?: number | null; omitTrailingZeroes?: boolean } = {},
) => {
  if (value === 0) {
    return '0';
  }

  const opts = {
    precision: 0,
    thousand: ',',
    decimal: '.',
  };

  const negative = value < 0 ? '-' : '';
  const appliedPrecision = precision || opts.precision;
  const base = parseInt(toFixed(Math.abs(value || 0), appliedPrecision), 10) + '';
  const mod = base.length > 3 ? base.length % 3 : 0;

  const result =
    negative +
    (mod ? base.slice(0, mod) + opts.thousand : '') +
    base.slice(mod).replace(/(\d{3})(?=\d)/g, '$1' + opts.thousand) +
    (appliedPrecision ? opts.decimal + toFixed(Math.abs(value), appliedPrecision).split('.')[1] : '');

  return omitTrailingZeroes ? result.replace(/\.?0+$/, '') : result;
};

export const formatPercentage = (value: number, precision = 2) => {
  const res =
    value !== 0 && value < 1
      ? '<1'
      : formatNumber(value, {
          omitTrailingZeroes: true,
          precision,
        });
  return `${res}%`;
};

export const compactNumber = (num: number) => {
  if (typeof num !== 'number' || Number.isNaN(num)) {
    throw new TypeError('Input must be a number');
  }

  const absNum = Math.abs(num);
  const sign = Math.sign(num);

  if (absNum < 1000) {
    return num.toString();
  }

  const suffixes = [
    { value: 1e9, symbol: 'B' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'k' },
  ];

  const suffix = suffixes.find((item) => absNum >= item.value * 0.995);
  if (!suffix) return num.toString();

  const scaled = absNum / suffix.value;
  const rounded =
    scaled >= 100 ? Math.round(scaled) : scaled >= 10 ? Math.round(scaled * 10) / 10 : Math.round(scaled * 100) / 100;

  return `${sign < 0 ? '-' : ''}${rounded}${suffix.symbol}`;
};
