import React from 'react';
import { type LinkProps, useRouterState, defaultParseSearch } from '@tanstack/react-router';

import { useStore } from 'services/store';
import { useTranslate } from 'services/i18n/useTranslate';
import type { FlattenedTranslationKeys } from 'types/translations';

import { Link } from 'components/common/Link';
import { type MetaProps } from 'components/layouts/AppLayout/PageHeader';

type BreadcrumbItem = {
  pageTitle: FlattenedTranslationKeys;
  pageDescription: FlattenedTranslationKeys;
  path: LinkProps['to'];
  search: LinkProps['search'];
};

export const Breadcrumb = () => {
  const t = useTranslate();
  const lastSearches = useStore((s) => s.lastPageSearchSlice.searches);

  const breadcrumbs = useRouterState({
    select: (state) => {
      return state.matches
        .map((match) => {
          const meta = match.meta ? (match.meta as MetaProps[]) : undefined;
          const pageTitle = meta?.find((item) => item.name === 'pageTitle')?.content as FlattenedTranslationKeys;

          if (!pageTitle) {
            return undefined;
          }

          const pageDescription = meta?.find((item) => item.name === 'pageDescription')?.content;
          const path = match.pathname as LinkProps['to'];
          let search = undefined as LinkProps['search'];

          if (lastSearches[match.pathname]) {
            search = defaultParseSearch(lastSearches[match.pathname] || '');
          }

          return { pageTitle, path, pageDescription, search };
        })
        .filter((crumb): crumb is BreadcrumbItem => Boolean(crumb));
    },
  });

  return (
    <>
      <nav aria-label={t('common.breadcrumb')} className="flex">
        <ol role="list" className="flex items-center space-x-2 text-sm">
          {breadcrumbs.map((item, index) => (
            <React.Fragment key={index}>
              {index > 0 && <span className="font-light text-accent-12">{'>'}</span>}
              <li>
                <Link
                  to={item.path}
                  search={item.search}
                  className={index === breadcrumbs.length - 1 ? 'font-light !text-accent-12' : ''}
                >
                  {t(item.pageTitle)}
                </Link>
              </li>
            </React.Fragment>
          ))}
        </ol>
      </nav>
    </>
  );
};
