import { EventsPerSecondByPipeline } from './EventsPerSecondByPipeline';
import { TotalEventsByTenant } from './TotalEventsByTenant';
import { EventsPerSecondByDataSource } from './EventsPerSecondByDataSource';

export function ReportsScene() {
  return (
    <div className="flex w-full flex-col gap-3 p-4">
      <EventsPerSecondByPipeline />
      <section className="grid grid-cols-2 gap-3">
        <TotalEventsByTenant />
        <EventsPerSecondByDataSource />
      </section>
    </div>
  );
}
