import { createFileRoute } from '@tanstack/react-router';
import * as z from 'zod';

import { SetupPasswordErrorComponent, SetupPasswordScene } from 'components/scenes/SetupPassword';

export const Route = createFileRoute('/_auth/reset-password')({
  component: ResetPasswordPage,
  validateSearch: z.object({
    token: z.string(),
  }),

  errorComponent: SetupPasswordErrorComponent,
  head: () => ({
    meta: [{ name: 'metaTitle', content: 'reset_password.page_title' }],
  }),
});

function ResetPasswordPage() {
  const navigate = Route.useNavigate();
  const { token } = Route.useSearch();

  return (
    <SetupPasswordScene
      token={token}
      title="reset_password.page_title"
      onSuccess={(email) => {
        void navigate({
          to: '/',
          state: {
            data: {
              id: 'passwordReset',
              email,
            },
          },
        });
      }}
    />
  );
}
