import { useTranslate } from 'services/i18n/useTranslate';
import { format } from 'date-fns';
import {
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  LineChart,
  type TooltipProps as RTooltipProps,
  Line,
  Legend,
  Tooltip,
  YAxis,
} from 'recharts';
import { cn } from 'utils/styles';

import { timeFormatSimple } from 'consts/dateFormats';
import type { Prettify } from 'types/helpers';
import { toFixed } from 'utils/numbers';

import { ProtocolIcon } from 'components/common/Icons';
import { Button } from 'components/common/Button';

import { WidgetContainer } from './common/components/WidgetContainer';
import { TooltipContainer } from './common/components/TooltipContainer';
import { CHART_HEIGHT } from './common/consts';

const COLOR = ['var(--info-a8)', '#8E4EC6'];
const tenants = ['tenantA', 'tenantB'];

const data: {
  time: string;
  data: Record<string, number>;
}[] = [];

for (let i = 0; i < 14; i++) {
  data.push({
    time: new Date(2025, 0, 1, i).toISOString(),
    data: {
      tenantA: Math.random() * 0.8 + 0.2,
      tenantB: Math.random() * 0.15 + 0.05,
    },
  });
}

type LineChartDataPoint<T extends Record<string, number>> = Prettify<{
  data: T;
  time: string;
}>;

type TooltipProps = RTooltipProps<string, number>;

const CustomTooltip = ({ active, payload }: TooltipProps) => {
  if (!active || !payload?.length) {
    return null;
  }

  const data = payload[0]?.payload as LineChartDataPoint<Record<string, number>> | undefined;
  if (!data) {
    return null;
  }

  return (
    <TooltipContainer>
      <ul className="m-0 list-none space-y-1 p-0 text-text">
        <li className="mb-1.5">{format(new Date(data.time), timeFormatSimple)}</li>
        {Object.entries(data.data).map(([key, value]) => {
          const color = COLOR[tenants.indexOf(key)];
          return (
            <li key={key} className="flex items-center gap-2">
              <div className="size-4" style={{ backgroundColor: color }} />
              {key}: {toFixed(value, 2)}
            </li>
          );
        })}
      </ul>
    </TooltipContainer>
  );
};

const YTick = ({
  x,
  y,
  payload,
  tickFormatter,
  isLoading,
}: {
  x?: number;
  y?: number;
  payload?: { value: number };
  tickFormatter?: (value: string | number) => string;
  isLoading?: boolean;
}) => {
  if (isLoading) {
    return null;
  }

  const label = payload?.value || '';
  return (
    <text x={x} y={y} dx={-10} dy={3} fontSize={12} textAnchor="middle" className="select-none fill-neutral-12">
      {tickFormatter ? tickFormatter(label) : label}
    </text>
  );
};

const XTick = ({
  x,
  y,
  payload,
  tickFormatter,
  isLoading,
}: {
  x?: number;
  y?: number;
  payload?: { value: number };
  tickFormatter?: (value: string | number) => string;
  isLoading?: boolean;
}) => {
  if (isLoading) {
    return null;
  }

  const label = payload?.value || '';
  return (
    <text x={x} y={y} dy={10} fontSize={12} textAnchor="middle" className="select-none fill-neutral-12">
      {tickFormatter ? tickFormatter(label) : label}
    </text>
  );
};

export function EventsPerSecondByDataSource() {
  const t = useTranslate();

  const isLoading = false;
  const queryIsError = false;
  if (queryIsError) {
    return (
      <WidgetContainer
        title={t('reports.statistics.widgets.events_per_second_by_data_source.title')}
        headerLeft={<ProtocolIcon />}
        className="h-80"
      >
        {t('common.errors.error_loading_linechart')}
      </WidgetContainer>
    );
  }

  const hasNoData = false;
  if (hasNoData) {
    return (
      <WidgetContainer
        title={t('reports.statistics.widgets.events_per_second_by_data_source.title')}
        headerLeft={<ProtocolIcon />}
        className="h-80"
      >
        {t('common.errors.no_data')}
      </WidgetContainer>
    );
  }

  return (
    <WidgetContainer
      title={t('reports.statistics.widgets.events_per_second_by_data_source.title')}
      headerLeft={<ProtocolIcon />}
      className="h-80"
      headerRight={
        <div className="flex items-center gap-4">
          <Button variant="outline" size="1" color="gray">
            {t('reports.statistics.widgets.events_per_second_by_data_source.buttons.day')}
          </Button>
          <Button variant="ghost" size="1" color="gray">
            {t('reports.statistics.widgets.events_per_second_by_data_source.buttons.month')}
          </Button>
        </div>
      }
    >
      <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
        <LineChart
          data={data}
          margin={{
            top: 16,
            right: 8,
            left: -24,
            bottom: -8,
          }}
        >
          <CartesianGrid vertical={false} stroke="var(--neutral-a6)" />

          <XAxis
            dataKey="time"
            tickFormatter={(x: string) => format(new Date(x), timeFormatSimple)}
            tick={<XTick isLoading={isLoading} />}
          />

          <YAxis axisLine={false} tickLine={false} tick={<YTick isLoading={isLoading} />} />

          <Tooltip content={<CustomTooltip />} />

          {!isLoading && (
            <Legend
              formatter={(value: string) => (
                <span className="ml-1 text-sm text-neutral-12">{value.replace('data.', '')}</span>
              )}
            />
          )}

          {tenants.map((tenant, index) => (
            <Line
              key={`line-${tenant}`}
              type="monotone"
              dataKey={`data.${tenant}`}
              stroke={COLOR[index]}
              dot={false}
              legendType="square"
              className={cn(isLoading && 'animate-skeleton-fill-pulse')}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </WidgetContainer>
  );
}
