import { PageBackground } from 'components/common/PageBackground';

import loadingSVG from 'static/images/loading.svg';
import { useTranslate } from 'services/i18n/useTranslate';

export function FullPageLoader() {
  const t = useTranslate();

  return (
    <div className="flex h-screen w-screen items-center justify-center bg-neutral-2">
      <PageBackground />

      <img src={loadingSVG} alt={t('common.loading_logo')} />
    </div>
  );
}
