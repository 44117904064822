import { addMilliseconds, format } from 'date-fns';
import { MixerHorizontalIcon } from '@radix-ui/react-icons';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  type TooltipProps as RTooltipProps,
} from 'recharts';

import { timeFormat, timeFormatSimple } from 'consts/dateFormats';
import { Button } from 'components/common/Button';
import { useTranslate } from 'services/i18n/useTranslate';
import type { Prettify } from 'types/helpers';

import { WidgetContainer } from './common/components/WidgetContainer';

const COLOR = ['#2d87b4', '#8da4ef', '#7ce2fe', '#7bccc4', '#2c8c5e', '#1f5e41'];

const pipelines = ['Pipeline 1', 'Pipeline 2', 'Pipeline 3', 'Pipeline 4', 'Pipeline 5'];

const data: {
  time: string;
  data: Record<string, number>;
}[] = [];

// initiate data for a day in 10 min intervals
for (let i = 0; i < 144; i++) {
  data.push({
    time: new Date(new Date().getTime() - i * 60000).toISOString(),
    data: pipelines.reduce(
      (acc, pipeline) => {
        const val =
          i === 0
            ? Math.floor(Math.random() * 100) + 100
            : (() => {
                const previousData = data?.[i - 1]?.data?.[pipeline];
                const addition = (Math.floor(Math.random() * 100 - 50) / 10) * Math.sign(Math.random() - 0.5);

                return Math.max(
                  Number.parseInt(
                    // @ts-expect-error mock data
                    (previousData + addition).toFixed(2),
                  ),
                  10,
                );
              })();

        acc[pipeline] = val;

        return acc;
      },
      {} as Record<string, number>,
    ),
  });
}

data.reverse();

type HistogramDataPoint<T extends Record<string, number>> = Prettify<{
  data: T;
  time: string;
}>;
type HistogramTooltipProps = RTooltipProps<string, number>;

const TooltipContent = ({ active, payload }: HistogramTooltipProps) => {
  const t = useTranslate();
  const data = payload?.[0]?.payload as HistogramDataPoint<Record<string, number>> | undefined;

  if (!active || !data) {
    return null;
  }

  return (
    <div className="rounded-1 bg-[var(--black)] px-1 py-0.5 text-xs">
      <div className="text-neutral-1 dark:text-neutral-12">
        {t('anomalies.histogram.tooltip_interval', {
          time1: format(new Date(data.time), timeFormatSimple),
          time2: format(addMilliseconds(new Date(data.time), 1000), timeFormatSimple),
        })}
      </div>

      <div className="mb-1 mt-2 space-y-1 text-text">
        {Object.entries(data.data).map(([pipeline, value]) => (
          <div key={pipeline} className="flex items-center gap-2">
            <div className="size-4" style={{ backgroundColor: COLOR[pipelines.indexOf(pipeline)] }} />
            <span>
              {pipeline}: {value}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export function EventsPerSecondByPipeline() {
  const t = useTranslate();

  return (
    <WidgetContainer
      title={t('reports.statistics.widgets.eps_by_pipeline.title')}
      headerLeft={<MixerHorizontalIcon />}
      headerRight={
        <div className="flex items-center gap-4">
          <Button size="1" variant="surface" color="gray">
            {t('common.day')}
          </Button>
          <Button size="1" variant="ghost" color="gray">
            {t('common.month')}
          </Button>
        </div>
      }
      className="h-[306px]"
    >
      <ResponsiveContainer width="100%" height={200}>
        <AreaChart
          data={data}
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="time"
            tickFormatter={(x: string) => format(new Date(x), timeFormat)}
            stroke="var(--colors-text)"
            tick={{
              fontSize: 12,
            }}
          />
          <YAxis
            tickCount={4}
            stroke="var(--colors-text)"
            tick={{
              fontSize: 12,
            }}
          />
          <Tooltip content={<TooltipContent />} />

          {pipelines.map((pipeline, index) => (
            <Area
              key={pipeline}
              type="monotone"
              dataKey={`data.${pipeline}`}
              stackId="1"
              stroke={COLOR[index]}
              fill={COLOR[index]}
            />
          ))}
          <ReferenceLine y={525} isFront stroke="var(--accent-10)" />
        </AreaChart>
      </ResponsiveContainer>
      <div className="mt-4 flex items-center gap-12">
        <div className="flex items-center gap-4 pl-16 text-sm">
          {pipelines.map((pipeline, index) => (
            <div key={pipeline} className="flex items-center gap-2">
              <div className="size-4" style={{ backgroundColor: COLOR[index] }} />
              <span>{pipeline}</span>
            </div>
          ))}
        </div>
        <div className="flex items-center gap-2 text-sm">
          <div className="h-0.5 w-4 bg-accent-9" />
          <div>{t('reports.statistics.widgets.eps_by_pipeline.95th_percentile')}</div>
        </div>
      </div>
    </WidgetContainer>
  );
}
