import { type ComponentType, type ReactNode } from 'react';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { useRouterState, defaultParseSearch } from '@tanstack/react-router';

import { cn } from 'utils/styles';
import { useStore } from 'services/store';
import { useTranslate } from 'services/i18n/useTranslate';
import { useLazyHasAccess } from 'services/auth/useHasAccess';

import { Link } from 'components/common/Link';
import { Text } from 'components/common/Text';

import { type MenuItem } from './config';

type LinkMenuItem = Omit<MenuItem, 'icon'> & { expanded: boolean; icon: ComponentType<any> };

const baseClass = cn(
  'relative flex h-11 w-full items-center whitespace-nowrap !rounded-3 px-3 leading-3 no-underline transition-colors duration-75 ease-in-out hover:bg-neutral-6 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-accent-9 focus-visible:ring-offset-0',
);

function LinkMenuItem(props: LinkMenuItem & { children: ReactNode }) {
  const t = useTranslate();
  const getHasAccess = useLazyHasAccess();
  const { pathname } = useRouterState().location;
  const to = props.getTo(getHasAccess);
  const isActive = props.getIsActive(pathname);
  const lastSearchState = useStore((state) => state.lastPageSearchSlice.searches[to] || '');

  return (
    <NavigationMenu.Link asChild active={isActive}>
      <Link
        to={to}
        search={lastSearchState ? defaultParseSearch(lastSearchState) : undefined}
        className={cn(
          baseClass,
          isActive ? 'bg-gradient-to-b from-[#FF7525] to-[#FFC800] !text-accent-1' : '!text-accent-a12',
        )}
        aria-label={t(props.tkey)}
      >
        {props.children}
      </Link>
    </NavigationMenu.Link>
  );
}

export function MenuItem(props: LinkMenuItem) {
  const t = useTranslate();

  const children = (
    <>
      <props.icon width="16px" height="16px" aria-hidden />
      <Text
        ml="2"
        size="2"
        className={cn(
          'absolute left-8 top-1/2 -translate-y-1/2 transform select-none leading-3 transition-opacity duration-200 ease-in-out',
          props.expanded ? 'opacity-100' : 'opacity-0',
        )}
      >
        {t(props.tkey)}
      </Text>
    </>
  );

  return <LinkMenuItem {...props}>{children}</LinkMenuItem>;
}
