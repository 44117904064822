import { useMemo, useState, useEffect, useRef } from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  createColumnHelper,
} from '@tanstack/react-table';
import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { LOADING_THREATS_TIMELINE } from 'mocks/threat';
import { fullDateTimeFormat } from 'consts/dateFormats';
import { useTranslate } from 'services/i18n/useTranslate';
import type { components } from 'types/schemas/api-schema';
import { useUpdateThreatWorkflow } from 'services/api/threats';

import { Table, SortableColumnHeaderCell } from 'components/common/Table';
import { Collapsible } from 'components/common/Collapsible';
import { Text } from 'components/common/Text';
import { Skeleton } from 'components/common/Skeleton';
import { CollapsibleBaseIcon } from 'components/common/Icons';
import { Button } from 'components/common/Button';
import { Form, FormControl, FormField, FormItem, FormMessage } from 'components/common/Form';
import { TextField } from 'components/common/TextField';
import { useCurrentUser } from 'services/api/auth';
import { useGetCallerDisplayName } from 'services/api/callers';

type ThreatWithTimeline = components['schemas']['threatWithTimeline'];
type Timeline = ThreatWithTimeline['timeline'][0];

const DEFAULT_DATA: Timeline[] = [];
const TimelineColumnHelper = createColumnHelper<Timeline>();

const TimelineTable = ({ isLoading, timeline = [] }: { isLoading: boolean; timeline: Timeline[] }) => {
  const t = useTranslate();
  const { getCallerDisplayName, isLoading: isCallerLoading } = useGetCallerDisplayName();

  const columns = useMemo(() => {
    return [
      TimelineColumnHelper.accessor('user', {
        header: () => <Text>{t('threats.change_log.table.headers.user')}</Text>,
        cell: (info) => {
          const { type, id } = info.row.original.user;
          return (
            <Skeleton isLoading={isLoading || isCallerLoading}>
              <Text>{getCallerDisplayName(id, type)}</Text>
            </Skeleton>
          );
        },
      }),
      TimelineColumnHelper.accessor('event', {
        id: 'source_entity',
        header: () => <Text>{t('threats.change_log.table.headers.event')}</Text>,
        cell: (info) => (
          <Skeleton isLoading={isLoading}>
            <Text>{info.getValue()}</Text>
          </Skeleton>
        ),
      }),
      TimelineColumnHelper.accessor('message', {
        id: 'destination_entity',
        header: () => <Text>{t('threats.change_log.table.headers.message')}</Text>,
        cell: (info) => (
          <Skeleton isLoading={isLoading}>
            <Text>{info.getValue()}</Text>
          </Skeleton>
        ),
      }),
      TimelineColumnHelper.accessor('time', {
        id: 'type',
        header: () => <Text>{t('threats.change_log.table.headers.time')}</Text>,
        cell: (info) => (
          <Skeleton isLoading={isLoading}>
            <Text>{format(info.getValue(), fullDateTimeFormat)}</Text>
          </Skeleton>
        ),
      }),
    ];
  }, [t, getCallerDisplayName, isLoading, isCallerLoading]);

  const table = useReactTable({
    data: timeline ?? DEFAULT_DATA,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [
        {
          id: 'type',
          desc: false,
        },
      ],
    },
  });

  return (
    <Table.Root size="1">
      <Table.Header>
        {table.getHeaderGroups().map((headerGroup) => (
          <Table.Row key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <SortableColumnHeaderCell key={header.id} header={header} />
            ))}
          </Table.Row>
        ))}
      </Table.Header>

      <Table.Body>
        {table.getRowModel().rows.map((row) => {
          return (
            <Table.Row key={row.id} align="center">
              {row.getVisibleCells().map((cell) => {
                return (
                  <Table.Cell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Table.Cell>
                );
              })}
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table.Root>
  );
};

const messageSchema = z.object({
  message: z.string(),
});

export type FormFields = z.infer<typeof messageSchema>;

export const ThreatChangeLog = ({
  isLoading,
  threatId,
  timeline,
}: {
  isLoading: boolean;
  threatId?: string;
  timeline?: Timeline[];
}) => {
  const t = useTranslate();
  const queryClient = useQueryClient();
  const { data: currentUser } = useCurrentUser();
  const updateThreatWorkflowStatusMutation = useUpdateThreatWorkflow(threatId!);

  const timelineData = timeline || LOADING_THREATS_TIMELINE || [];
  const [displayMessageInput, setDisplayMessageInput] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (displayMessageInput && inputRef.current) {
      inputRef.current.focus();
    }
  }, [displayMessageInput]);

  const form = useForm<FormFields>({
    resolver: zodResolver(messageSchema),
    defaultValues: {
      message: '',
    },
  });

  const onCancel = () => {
    setDisplayMessageInput(false);
    form.reset();
  };

  const onSubmit = (data: FormFields) => {
    const newMessage = {
      user: { id: currentUser?.data.id, type: 'user' },
      event: t('threats.change_log.comment_added'),
      message: data.message,
      time: new Date().toISOString(),
    } as Timeline;

    queryClient.setQueryData(['threats', threatId], (data: { data: components['schemas']['threatWithTimeline'] }) => {
      return { data: { ...data.data, timeline: [...data.data.timeline, newMessage] } };
    });

    updateThreatWorkflowStatusMutation.mutate(
      { message: data.message },
      {
        onError: () => {
          queryClient.setQueryData(
            ['threats', threatId],
            (data: { data: components['schemas']['threatWithTimeline'] }) => {
              return { data: { ...data.data, timeline: data.data.timeline.filter((d) => d !== newMessage) } };
            },
          );
        },
      },
    );

    onCancel();
  };

  return (
    <Collapsible
      variant="transparent"
      Icon={CollapsibleBaseIcon}
      title={t('common.timeline')}
      defaultOpen
      badge={timelineData.length}
    >
      <div className="mb-3 space-y-3">
        <Text className="pl-2" size="2" weight="medium">
          {t('threats.change_log.header')}
        </Text>

        {timelineData && <TimelineTable isLoading={isLoading} timeline={timelineData} />}

        {displayMessageInput ? (
          <Form {...form}>
            <form
              className="mb-3 ml-0.5 flex gap-2"
              onKeyDown={async (ev) => {
                if (ev.key === 'Enter') {
                  const message = form.getValues('message')?.trim();
                  if (message) {
                    await form.handleSubmit(onSubmit)();
                  }
                }
              }}
              onSubmit={form.handleSubmit(onSubmit)}
            >
              <FormField
                control={form.control}
                name="message"
                render={({ field, fieldState }) => {
                  return (
                    <FormItem className="w-96">
                      <FormControl>
                        <TextField {...field} size="1" ref={inputRef} />
                      </FormControl>

                      {fieldState.error && <FormMessage />}
                    </FormItem>
                  );
                }}
              />

              <Button variant="outline" size="1" onClick={onCancel}>
                {t('common.cancel')}
              </Button>
              <Button
                disabled={!form.watch('message')}
                isLoading={updateThreatWorkflowStatusMutation.isPending}
                type="submit"
                size="1"
              >
                {t('common.add')}
              </Button>
            </form>
          </Form>
        ) : (
          <Button variant="outline" size="1" onClick={() => setDisplayMessageInput(true)}>
            {t('threats.change_log.add_message')}
          </Button>
        )}
      </div>
    </Collapsible>
  );
};
