import { createFileRoute } from '@tanstack/react-router';

import { NotFoundPage } from 'components/scenes/NotFoundPage';
import { SettingsScene } from 'components/scenes/Settings';

export const Route = createFileRoute('/_app/settings')({
  component: SettingsScene,
  head: () => ({
    meta: [
      {
        name: 'pageTitle',
        content: 'settings.page_title',
      },
      {
        name: 'metaTitle',
        content: 'settings.page_title',
      },
      {
        name: 'pageDescription',
        content: 'settings.page_description',
      },
    ],
  }),
  notFoundComponent: () => <NotFoundPage />,
});
