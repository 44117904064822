import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';

import { type paths } from 'types/schemas/api-schema';
import { useTranslate } from 'services/i18n/useTranslate';

import { api } from '.';

export const useCallers = () => {
  return useQuery({
    queryKey: ['callers'],
    queryFn: () => {
      return api
        .url('/auth/callers')
        .get()
        .json<paths['/auth/callers']['get']['responses']['200']['content']['application/json']>();
    },
  });
};

export const useGetCallerDisplayName = () => {
  const t = useTranslate();
  const { data: callersData, isLoading, isError, error } = useCallers();

  const getCallerDisplayName = useCallback(
    (callerId: string | null | undefined, fallback?: string) => {
      if (!callerId || !callersData) {
        return fallback;
      }

      const assignee = callersData?.data?.callers.find((c) => c.caller.id === callerId);

      if (!assignee) {
        return fallback;
      }

      if (assignee.name === null && assignee.deleted) {
        return t('common.deleted_user');
      }

      return assignee.name;
    },
    [callersData, t],
  );

  return {
    callersData,
    isLoading,
    isError,
    getCallerDisplayName,
    errorMessage: error instanceof Error ? error.message : undefined,
  };
};
